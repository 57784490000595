.upload__btn {
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover !important;
  background: #f3f3f3;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  display: flex;
  height: 6.9rem;
  justify-content: center;
  margin: 0 13px 20px;
  width: 6.9rem;
  i {
    color: #333;
    font-size: 3.4rem;
    margin: 0;
  }
}
.upload__container {
  align-items: center;
  display: flex;
  flex-direction: column;
}
