.account-index {
  .in-account-content .in-index-content .in-slider-container {
    top: 14.5rem;
  }
  .in-header-container {
    align-items: center;
    background: #000;
    display: flex;
    height: 13.7rem;
    justify-content: center;
    padding: 0;
    position: relative;
    .info-name {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 1rem;


      .in-account-info {
        text-align: center;
        .edit_info {
          display: inline-block;
          margin-left: 0.5rem;
          transform: translateY(7px);
          &:before {
            .IN-iconfont("\e714");            color: #ffffff;            font-size: 1.3rem;
          }
        }
        > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 0 auto;
          max-width: 80%;
          padding: 0 20%;
          text-align: center;
        }
        h5 {
          display: inline-block;
          font-size: 1.2rem;
          margin: 0.5rem 0 0.3rem;
          min-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .my-code {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 4px;
          color: white;
          font-size: 12px;
          margin-bottom: 9px;

          .text-to-copy {
            font-weight: bold;
          }

        }
        .my-code {
          img.edit-data {
            margin: 0;
            margin-inline: 5px;
            cursor: pointer;
            width: 15px;
            height: 20px;
            border: 0;
            border-radius: 0;
            background: unset;

          }

          img.copy-button {
            margin: 0;
            margin-inline: 5px;
            cursor: pointer;
            width: 20px;
            height: 20px;
            border: 0;
            border-radius: 0;
            opacity: 1;
            background: unset;
            filter: invert(100%);
          }

          img.copy-button:active {
            opacity: .2;
          }

        }

      }
      img {
        border: 0.2rem solid white;
        height: 6rem;
        width: 6rem;
      }
    }
    > a {
      position: absolute;
      right: 0.8rem;
      top: 1rem;
      .icon-zhuxiao &:before {
        .IN-iconfont("\e715");          color: #ffffff;
      }
    }

    .content-con {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-block: 15px;
      padding-inline: 32px;

      .header-form {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-top: 20px;
        color: #000;
        font-size: 16px;
        margin-bottom: 9px;
        width: 100%;

        #text-to-copy {
          font-weight: bold;
        }

        .line {
          margin-top: 20px;
          width: 100%;
          height: 1px;
          background: darkgrey;
        }

      }

      .form-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .context {
          font-size: 12px;
          font-weight: bold;
          margin-block: 16px;
        }

        .input-form-con {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;

          .label-form {
            //color: #d0d0d0;
            display: inline-block;
            font-size: 16px;
            height: auto;
            line-height: 1em;
            text-wrap: nowrap;
          }

          .input-code{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;

            .input-form {
              background-color: #f7f7f7;
              width: 100%;
              border-radius: 0;
              border: 0;
              font-size: 12px;
              color: #333;
              height: 70px;
              padding: 12px;
              margin-block: 10px;
              box-sizing: border-box;
              //outline: none;
            }

            .empty-data {
              font-size: 12px;
              color: red;
            }

          }


          button {
            background: #000;
            border-radius: 6px;
            font-size: 16px;
            //height: 45px;
            //width: 400px;
            padding-inline: 30px;
            padding-block: 20px;
            color: white;
          }
        }

      }

    }

  }
  .in-menus-container {
    padding: 0 0.8rem;
    .in-menus-order ul li {
      > p {
        color: #666666;
      }
      i:before {
        font-size: 4rem;
      }
    }
    .in-menus-services {
      .icon-address {
        background-image: url("../images/account/m1.png");
      }
      .icon-affiliates {
        background-image: url("../images/account/affiliates.png");
      }
      .icon-affiliates-bill {
        background-image: url("../images/account/affiliates-bill.png");
      }
      .icon-affiliates-list {
        background-image: url("../images/account/affiliates-list.png");
      }
      .icon-affiliates-order {
        background-image: url("../images/account/affiliates-order.png");
      }
      .icon-card-bag {
        background-image: url("../images/account/card-bag.png");
      }
      .icon-coupon {
        background-image: url("../images/account/m2.png");
      }
      .icon-fans {
        background-image: url("../images/account/fans.png");
      }
      .icon-order-report {
        background-image: url("../images/account/order-report.png");
      }
      .icon-points,.icon-pp-warehouse {
        background-image: url("../images/account/points.png");
      }
      .icon-points-history {
        background-image: url("../images/account/points-history.png");
      }
      .icon-pp-info,.icon-pp-speedAf {
        background-image: url("../images/account/pp-info.png");
      }
      .icon-pp-inventory {
        background-image: url("../images/account/pp-inventory.png");
      }
      .icon-pp-orders {
        background-image: url("../images/account/pp-orders.png");
      }
      .icon-rma,i {
        background-image: url("../images/account/m4.png");
      }
      .icon-share-statistics {
        background-image: url("../images/account/share-statistics.png");
      }
      .icon-yushou {
        background-image: url("../images/account/m3.png");
      }
      li{
          position: relative;
          i {
            background-size: contain;
            color: transparent;
            height: 3.4rem;
            width: 3.4rem;
          }
          span.badge {
                position: absolute;
                display: block;
                width: 10px;
                height: 10px;
                font-size: 8px;
                background: red;
                /* box-shadow: 0px 0px 5px red; */
                line-height: 15px;
                text-align: center;
                border-radius: 5px;
                right: 15px;
                top: 15px;
                color: white;
                font-weight: bold;
          }
      }
    }
    > div {
      border-radius: 0.3rem;
      box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
      margin: 0 0 0.8rem;
      padding: 0 0.8rem;
      h2 {
        color: #999999;
        font-size: 1rem;
        line-height: 2.7rem;
        padding: 0;
        i {
          display: none;
        }
      }
      ul li {
        border: none;
        &.menu-user {
          display: none;
        }
        .icon-order_canceled &:before {
          .IN-iconfont("\e713");
        }
        .icon-order_complete &:before {
          .IN-iconfont("\e70f");
        }
        .icon-order_pending &:before {
          .IN-iconfont("\e70e");
        }
        .icon-order_processing &:before {
          .IN-iconfont("\e70d");
        }
        i {
          color: #333333;
        }
      }
    }
  }


}

.in-modal .close_icon {
  position: absolute;
  right: 1.3rem;
  top: 1.3rem;
  z-index: 5;
  &:before {
    .IN-iconfont("\e6f7");          transform: rotate(45deg);          color: #999999;          display: block;
  }
}

.in-modal.validation {
  width: 100%;
  padding: 10px;

  .info {
    margin: 70px 0 20px 0;
    text-align: center;
  }

 .xp-icon-close{
   position: absolute;
   right: 1.3rem;
   top: 1.3rem;
   z-index: 5;
   &:before {
     .IN-iconfont("\e6f7");          transform: rotate(45deg);          color: #999999;          display: block;
   }
 }

  .in-btn-group-new {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
  }
  .in-btn.in-continue-btn a {
    background: red;
  }
}
