.in-quick-entry {
  text-align: center;
  i {
    .centered-line(3.2rem);
    .font-size-rem(40px);
    .inline-block;
    border-radius: 50%;
    color: #fff;
    width: 3.2rem;
  }
  li {
    flex: 1;
    &:nth-child(1) i {
      background: @icon-star;
    }
    &:nth-child(2) i {
      background: @icon-walfare;
    }
    &:nth-child(3) i {
      background: @icon-groupon;
    }
    &:nth-child(4) i {
      background: @icon-member;
    }
    &:nth-child(5) i {
      background: @icon-club;
    }
  }
  p {
    .centered-line(2em);
    .margin-top-rem(10px);
    color: #121212;
  }
}
