.in-review-pics {
  .margin-top-rem(20px);
  display: flex;
  flex-wrap: wrap;
  div {
    .margin-rem(0 20px 20px 0);
    .size(5rem);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    border: 1px solid @input-border-color;
    overflow: hidden;
  }
  i {
    .font-size-rem(100px);
    .margin-bottom-rem(20px);
    border-radius: 5px;
    border: 1px solid @input-border-color;
  }
}
.in-review-row {
  .centered-line(3.7em);
  .font-size-rem(18px);
  .margin-top-rem(20px);
  .padding-rem(0 26px);
  background: #fff;
  border-radius: 2em;
  border: 1px solid @input-border-color;
  display: flex;
  justify-content: space-between;
  position: relative;
  &.in-textarea {
    .padding-rem(20px 26px);
    height: 6rem;
  }
  &.is-danger {
    border-color: #f00;
  }
  input {
    .font-size-rem(18px);
    background-color: #fff;
    color: @font-color;
    flex: 1;
    &:-webkit-autofill {
      box-shadow: inset 0 0 0 2rem #fff;
    }
  }
}
