.discount-code {
  background-color: #000000;
  color: #fff;
  height: 100vh;
  &__container {
    background-image: url(../images/discount_code/header@2x.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 29px;
  }
  &__header {
    padding-top: 51px;
    text-align: center;
  }
  &__next-timer {
    color: #FFDCC2;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 20px;
    .code__timer__text {
      margin-right: 10px;
    }
  }
  &__title {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(174deg, #FFCCAB 0%, #FFFFFF 100%);
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1;
    margin: 10px 0;
  }
  .in-modal {
    color: #333;
    a {
      color: #ff9017;
    }
    p {
      line-height: 1.2;
    }
  }
}
.discount-code__card {
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  width: 430px;
  &__button {
    background: none;
    border: none;
    color: #FF6C00;
    display: block;
    font-size: 18px;
    height:100%;
    outline: none;
    text-align: center;
    width: 100%;
  }
  &__des {
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
  }
  &__end-time {
    font-size: 16px;
    line-height: 1.2;
    margin-top: 10px;
    margin: 0;
  }
  &__footer {
    height: 70px;
  }
  &__header {
    display: flex;
    height: 155px;
    padding: 15px;
  }
  &__right {
    padding-left: 10px;
    text-align: left;
    width: 68%;
  }
  &__title {
    font-size: 28px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 10px;
    margin: 0;
  }
  &__total {
    align-items: center;
    display: flex;
    font-size: 36px;
    justify-content: center;
    width: 32%;
  }
}
.discount-code__card.Coming.soon {
  background-image: url(../images/discount_code/card_coming_soon@2x.png);
  .discount-code__card__button {
    color: #BC8866;
  }
}
.discount-code__card.Finished {
  background-image: url(../images/discount_code/card_finished@2x.png);
  .discount-code__card__button {
    color: #fff;
  }
}
.discount-code__card.Ongoing {
  background-image: url(../images/discount_code/card_ongoing@2x.png);
}
.discount-code__content {
  background-image: url(../images/discount_code/content@2x.png);
  background-position-x: 50%;
  background-position-y: -17px;
  background-repeat: no-repeat;
  background-size: contain;
  height: 268px;
  margin: 20px 0 40px;
  position: relative;
}
.discount-code__item {
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%,);
}
.discount-code__timer {
  display: flex;
  justify-content: center;
  padding: 20px;
  &-item {
    align-items: center;
    background: #171717;
    border-radius: 4px;
    border: 1px solid #FF6C00;
    box-shadow: 0px 2px 5px 0px rgba(255, 110, 110, 0.5);
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    font-size:14px;
    justify-content: center;
    opacity: 0.81;
    padding: 6px 5px;
    & {
      + & {
        margin-left: 16px;
      }
      span {
        display:block;
        font-size: 18px;
        margin: 5px 0;
        width: 100%;
      }
    }
    &.disabled {
      background-color: #4C4C4C;
      border-color: #4C4C4C;
      box-shadow: none;
      color: #A1A1A1;
      opacity: 1;
    }
  }
}
.discount-code__total {
  color: #FFDCC2;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 20px 0;
  text-align: center;
}
.discount-code__wrapper {
  background-color: #000;
  margin-top: 40px;
}
