.index-list {
  height: 100%;
  position: relative;
  width: 100%;
  .index-list-fixed {
    -webkit-box-sizing: border-box;
    background: #f7f7f7;
    box-sizing: border-box;
    color: #999;
    font-size: 14px;
    left: 0;
    line-height: 1;
    padding: 16px 16px 10px 16px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .index-list-nav {
    -webkit-transform: translateY(-50%);
    font-family: Helvetica;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 30;
    > ul {
      margin: 0;
      padding: 0;
      > li {
        color: #666;
        font-size: 12px;
        line-height: 1;
        padding: 6px 16px 0 16px;
        text-align: center;
      }
      > li.active {
        color: #3b99fc;
      }
    }
  }
  .list-wrapper {
    background: #fff;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    .index-list-content {
      background: #fff;
      border-radius: 2px;
      .index-list-anchor {
        background: #f7f7f7;
        color: #999;
        font-size: 14px;
        line-height: 1;
        padding: 16px 16px 10px 16px;
      }
      .index-list-item {
        color: #333;
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        padding: 0 16px;
        position: relative;
      }
      .index-list-item:last-child:after,.index-list-item:last-child:before {
        display: none;
      }
      .index-list-item_active {
        background: rgba(0,0,0,0.04);
      }
      .index-list-title {
        color: #333;
        font-size: 14px;
        line-height: 1.6;
        padding: 14px 16px;
      }
    }
  }
}
