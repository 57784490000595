.in-sign-banner {
  .size(100%, 15rem);
}
.in-sign-container {
  position: relative;
  .in-input-row {
    background: #fff;
    input &:-webkit-autofill {
      box-shadow: inset 0 0 0 66rem #fff;
    }
  }
}
.in-sign-nav {
  .centered-line(3.8rem);
  .padding-rem(0 20px);
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  i {
    .font-size-rem(44px);
    color: #fff;
  }
}
