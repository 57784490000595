.in-product-actions {
  background: #fff;
  bottom: 0;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, .2);
  display: flex;
  left: 0;
  line-height: 3.6rem;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 1;
  &.in-absolute {
    position: absolute;
  }
  .in-btn {
    .font-size-rem(14px);
    .font-size-rem(32px);
    .inline-block;
    align-items: center;
    background-color: #000;
    // background: #FF9013;
    color: #fff;
    display: flex;
    flex-grow: 1;
    flex: 1;
    height: auto;
    justify-content: center;
    line-height: 1.2em;
    text-transform: uppercase;
    width: auto;
    &.active {
      background: #ff3200;
    }
    &.in-buy-now {
      color: @font-color;
    }
    &.in-disabled {
      background: @dark-border;
    }
    &.in-group-sales {
      background: @orange;
    }
    &.in-pre-order {
      background: @red;
    }
  }
  i {
    .font-size-rem(40px);
    .inline-block;
    border-right: 1px solid @border-color;
    width: 3.6rem;
    &.icon-cart {
      position: relative;
      span {
        .centered-line(1rem);
        .font-size-rem(16px);
        background: #333;
        border-radius: 50%;
        color: #fff;
        position: absolute;
        right: .3rem;
        text-align: center;
        top: .7rem;
        width: 1rem;
      }
    }
    &.in-wishlist-red {
      color: #ef2525;
    }
  }
}
.in-product-info .in-switch {
  animation: .3s ease-in-out forwards;
  display: flex;
  position: relative;
  width: 100%;
  &.slideInLeft {
    animation-name: slideInLeft;
  }
  &.slideOutLeft {
    animation-name: slideOutLeft;
    justify-content: flex-end;
  }
  > div {
    width: 100%;
  }
}
.in-product-nav {
  align-items: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  a {
    color: #000;
  }
  li {
    border: 1px solid #999;
    margin-right: -1px;
    &:first-child {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }
    &:last-child {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
    }
    a {
      .centered-line(3.2rem);
      .font-size-rem(28px);
      .inline-block;
      color: @font-color;
      text-align: center;
      text-transform: uppercase;
      width: 7em;
      &.active {
        background: #999;
        color: #fff;
      }
    }
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.in-product-package {
  background: @border-color;
  .in-package-info {
    .margin-rem(0 30px);
    flex: 1;
  }
  del {
    color: @light-gray;
  }
  i {
    .inline-block;
    .size(2.2rem);
    background: #fff;
    border-radius: 50%;
    border: 1px solid @gray;
    color: #fff;
  }
  img {
    .size(3.6rem);
  }
  section {
    .padding-rem(0 30px);
    align-items: center;
    border-bottom: 1px solid #fff;
    display: flex;
    height: 6.5rem;
    justify-content: space-between;
    &.active i {
      background: @green;
      border-color: @green;
      box-shadow: inset 0 0 0 3px #fff;
    }
    &.inactive {
      opacity: 0.5;
    }
  }
  span {
    .font-size-rem(16px);
  }
}
.in-product-price {
  .in-origin-price {
    .font-size-rem(18px);
    .margin-left-rem(10px);
    color: @light-gray;
  }
  .in-price {
    .font-size-rem(30px);
  }
}
.in-product-qty {
  .centered-line(2.8rem);
  display: flex;
  i {
    background: @border-color;
    border-radius: 50%;
    position: relative;
    text-align: center;
    width: 2.5rem;
    z-index: 1;
    &:last-child {
      .font-size-rem(30px);
    }
  }
  p {
    background: @border-color;
    margin: 0 -1.25rem;
    text-align: center;
    width: 6em;
  }
}
.in-product-reviews-wrapper .in-slider-container {
  bottom: 0;
}
.in-product-reviews-wrapper {
  .in-pic-preview {
    img {
      background: #000;
    }
  }
}
.in-product-timer {
  .centered-line(3rem);
  .margin-bottom-rem(-10px);
  .padding-rem(0 20px);
  background: @orange;
  color: #fff;
  display: flex;
  justify-content: space-between;
  &.in-flash-sale {
    background: @green;
  }
  &.in-pre-order {
    background: @red;
  }
  .in-timer-title {
    .font-size-rem(24px);
    text-transform: capitalize;
  }
}
.in-productList-container {
  text-align: center;
  & {
    .in-product &:nth-child(6n) {
      margin-left: 0;
    }
    .in-product-list {
      .make-row();
      .padding-left-rem(20);
      .padding-right-rem(20);
      & > div {
        .make-column(1/5, 2%);
      }
    }
  }
  .in-btn {
    height: 3.6rem;
    line-height: 3.6rem;
    margin-bottom: 16px;
    padding: 0 0.8rem;
    width: 100%;
    button {
      background: #ff6b00;
      color: #fff;
      display: block;
      flex: 1;
      font-size: 1.6rem;
      height: 100%;
      text-align: center;
      width: 100%;
    }
  }
  .in-product-list {
    padding: 0.8rem;
    .in-product-list__content {
      display: flex;
      flex-wrap: wrap;
      float: none;
      margin: 0;
      width: 100%;
      .in-ads-product-img {
        background: #fff;
        position: relative;
        a {
          color: #333;
          display: inline-block;
        }
      }
      .in-ads-product-name {
        align-items: center;
        display: flex;
        font-size: 1.2rem;
        height: 3rem;
        justify-content: center;
        line-height: 28px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        & span {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          color: #333;
          display: -webkit-box;
          font-size: 1.3rem;
          line-height: 1.1;
          overflow: hidden;
          text-align: center;
        }
      }
      .in-flag-wrapper {
        display: flex;
        justify-content: center;
        padding: 0.4rem;
        position: static;
        right: 0;
        top: 0;
        span {
          color: #fff;
          font-size: 0.9rem;
          height: 1.5em;
          line-height: 1.3rem;
          margin: 0 0.15rem;
          padding: 0 0.4rem;
          &.in-new-icon {
            background: #5db383;
          }
          &.in-sale-icon {
            background: #ff3200;
          }
        }
      }
      .in-price-final,.in-price-max {
        color: #ff6b00;
        display: block;
        font-size: 1.7rem;
        font-weight: 500;
        height: auto;
        line-height: 28px;
        margin: 0.6rem 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .in-price-max.final {
        color: #999;
        font-size: 1rem;
        text-decoration: line-through;
      }
      .in-product {
        background: #fff;
        line-height: 1.6em;
        margin-bottom: 0.4rem;
        padding: 0.8rem;
        width: calc(~"(100% - 0.4rem) / 2");
        &:nth-child(odd) {
          margin-right: 0.4rem;
        }
      }
    }
  }
}
