.small-table {
  background-color: #fff;
  border-radius: .3rem;
  box-shadow: 0 0.05rem 0.7rem 0 rgba(0,0,0,.06);
  font-size: 16px;
  margin: .8rem 0;
  padding: 12px;
  input[type=number],select {
    background: #f9f9f9;
    width: 100%;
  }
  li {
    display: flex;
    + li {
      border-top: 1px solid #e3e3e3;
    }
    span {
      display: inline-block;
      padding: 12px;
      width: 50%;
    }
  }
  span + span {
    border-left: 1px solid #e3e3e3;
  }
  ul {
    border: 1px solid #e3e3e3;
  }
}
.table-responsive {
  overflow-x: auto;
  & ul {
    width: 200%;
  }
}
.full-table {
  border-bottom: 1px solid #ccc;
  li {
    height: auto!important;
    min-height: 50px;
    & > span {
      flex: 3!important;
    }
    .small {
      flex: 1!important;
    }
  }
  span {
    display: block;
    & span {
      line-height: 1.5;
      text-transform: capitalize;
    }
  }
}
