.shopping-cart {
  .in-cart-bar {
    height: 4.4rem;
    line-height: 4.4rem;
    .in-total {
      flex: 1 1 38%;
      .price {
        font-size: 1.25rem;
      }
      span {
        font-size: 0.9rem;
      }
    }
    a {
      flex: 1 1 64%;
      font-size: 1.45rem;
      background: #000;
    }
  }
  .in-shopping-cart-content {
    background: none;
    box-shadow: none;
    padding: 0.8rem;
    .in-item-container {
      .in-title {
        color: #aaaaaa;
        line-height: 1;
        margin-bottom: 0.8rem;
        text-align: left;
      }
      > ul > li {
        background: white;
        border-radius: 0.3rem;
        box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);
        margin-bottom: 0.8rem;
        display: flex;
        .icon-delete &:before {
          .IN-iconfont("\e703");              font-size: 2rem;              color: #999999;
        }
        .in-item-qty {
          align-items: center;
          border-radius: 0.75rem;
          display: flex;
          height: 1.5rem;
          justify-content: space-between;
          line-height: 1.5rem;
          overflow: hidden;
          width: 6.4rem;
          i {
            border-radius: 0;
            color: #333333;
            height: 1.5rem;
            margin: 0;
            width: 1.8rem;
          }
          input {
            flex: 1 1 2rem;
            height: 1.5rem;
            max-width: 3rem;
            width: 3rem;
          }
        }
        .in-item-subtotal {
          margin: 0.6rem 0;
          .price {
            font-size: 1.1rem;
          }
        }
        .item_name {
          color: #666666;
          font-size: 1.1rem;
        }
      }
    }
  }
  .in-shopping-cart-nav {
    background: white;
    border: none;
    h2 {
      font-size: 1.8rem;
      & + a {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  .in-slider-footer-container {
    bottom: 4.4rem;
  }
}
