.in-service-list {
  .padding-rem(0 16px);
  justify-content: space-between;
  a {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  i {
    .font-size-rem(52px);
    .margin-bottom-rem(10px);
    color: #666;
  }
  li {
    flex: 1;
    text-align: center;
  }
  p {
    .font-size-rem(16px);
    .inline-block;
    color: #333;
  }
}
