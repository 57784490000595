.static-page {
  & .in-slider-container {
    background: #fff;
  }
  .in-header-quick {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .in-menu-dropdown {
    box-shadow: 0 3px 5px rgba(0,0,0,.15);
    padding: 0;
    a {
      .padding-rem(0 12px);
      display: block;
      text-transform: capitalize;
      width: 100%;
    }
    li {
      margin: 0;
      &.active a {
        color: #000;
        font-weight: bold;
      }
    }
  }
  .in-menu-wrapper {
    position: fixed;
    top: 4.5rem;
    width: 100%;
    z-index: 9;
    ul li {
      border-bottom: 1px solid @border-color;
      display: block;
      line-height: 2.75rem;
    }
  }
}
.staticBox {
  line-height: 1.5em;
}
.staticTop {
  .box-shadow(0 3px 5px rgba(0, 0, 0, 0.24));
  .centered-line(3em);
  background: #fff;
  position: fixed;
  top: 4.5rem;
  width: 100%;
  z-index: 6;
  a {
    .font-size-rem(24px);
    .inline-block();
    .padding-rem(0 @spacing-fourfold);
    color: @font-color;
    &.active {
      border-bottom: 1px solid @green;
      color: @green;
      height: 2.75rem;
    }
  }
  ul {
    overflow: hidden;
    white-space: nowrap;
    li {
      .inline-block;
      &.active a {
        color: @green;
        font-weight: bold;
      }
      &:last-child {
        .padding-right-rem(60px);
      }
    }
    li.active a {
      color: #000;
    }
  }
}
