.account-address-index {
  .in-account-nav {
    border: none;
    h2 {
      font-size: 1.8rem;
      & + a {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  .in-add-address-container {
    .in-address-edit-container {
      background: white;
      border-radius: 0.2rem;
      margin: 0.8rem;
      padding: 0.8rem;
      .in-input-row {
        border-radius: 1.5rem;
        font-size: 1.4rem;
        height: 3rem;
        line-height: 3rem;
        input.readonly {
          background: #e1e1e1;
          pointer-events: none;
        }
      }
    }
    > .v--modal-overlay > .v--modal {
      background: #f9f9f9;
    }
  }
  .in-cart-bar {
    height: 4rem;
    line-height: 4rem;
  }
  .in-input-row {
    .icon-arrow-down &:before {
      .IN-iconfont("\e707");        color: #979797;        font-size: 1.4rem;
    }
    label {
      color: #bbbbbb;
    }
    select {
      background-color: transparent;
      padding: 0 0.8rem;
    }
    .xmall-icon.icon-arrow-down {
      position: absolute;
      right: 10px;
      font-size: 22px;
      color: #666;
      cursor: pointer;
      pointer-events: none;
    }
  }
  .in-input-row.select{
    position: relative;
  }
  .in-shipping-address {
    li {
      border-color: white;
      border-radius: 0.3rem;
      box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
      &.active {
        border-color: @c-orange;
      }
      .in-edit {
        border: none;
        padding-right: 0.8rem;
        > div {
          border-color: #e3e3e3;
          .icon-delete &:before {
            .IN-iconfont("\e703");
          }
          .icon-edit &:before {
            .IN-iconfont("\e6fb");
          }
          i {
            color: #666666;
            margin: 0.8rem 0;
          }
        }
      }
      .in-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          padding-bottom: 0.4rem;
          &.in-tel {
            color: #666666;
            font-size: 1rem;
          }
          &:first-of-type {
            font-size: 1.3rem;
          }
          &:nth-child(2) {
            font-size: 1.2rem;
          }
        }
      }
    }
    li.active {
      border-color: #000;
    }
  }
  .in-slider-footer-container {
    bottom: 4rem;
  }
}
