.xp-container {
  padding-left: 10px;
  padding-right: 10px;
}
.xp-header-silder-menu {
  background-color: #ffffff;
  padding-bottom: 24px;
  padding-top: 24px;
  & {
    .swiper-pagination {
      bottom: 0;
      margin-top: 6px;
      position: relative;
    }
    .swiper-pagination-bullet {
      background: #F3F3F3;
      border-radius: 8px;
      height: 8px;
      opacity: 1;
      width: 8px;
    }
    .swiper-pagination-bullet-active {
      background: @main-color;
      height: 8px;
      width: 20px;
    }
  }
  .swiper-pagination-bullet-active {
    background: #000;
  }
}
.xp-home-activity {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  padding-top: 0;
}
.xp-home-activity-item {
  display: flex;
  margin-bottom: 8px;
  width: calc(~"(100% - 8px) / 2");
  & a {
    position: relative;
    width: 100%;
    & img {
      height: auto;
      width: 100%;
    }
  }
  &:nth-child(2n+1) {
    margin-right: 8px;
  }
  &:nth-last-child(1),&:nth-last-child(2) {
    margin-bottom: 0;
  }
}
.xp-home-activity-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0 30% 0 5%;
  position: absolute;
  top: 0;
  width: 100%;
  & {
    .xp-activity-message {
      color: #666666;
      font-size: 0.8rem;
      line-height: 1.2em;
    }
    .xp-activity-title {
      color: #333333;
      font-family: @font-medium;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.2em;
    }
  }
}
.xp-icon-arrow-left:before {
  color: #fff;
}
.xp-icon-country:before,.xp-icon-menu:before {
  // color: lemonchiffon;
  color: #fff;
}
.xp-logo-image & img {
  width: 10rem;
}
.xp-menu-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  & {
    .xp-menu-name {
      color: #333333;
      font-size: 1rem;
      margin-top: 10px;
      text-align: center;
    }
    img {
      width: 4rem;
    }
  }
}
.xp-navbar-container {
  background-color: #000;
  display: flex;
  height: 4.4rem;
  .xp-navbar-content {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    p {
      font-size: 1.8rem;
      text-transform: uppercase;
    }
  }
  .xp-navbar-left-content,.xp-navbar-right-content {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 4rem;
  }
  i {
    .font-size-rem(36px);
    color: #979797;
  }
}
.xp-product-href-area {
  .in-flag-wrapper span {
    font-size: 12px;
  }
  .xp-product-max-price {
    color: #979797;
    font-size: 12px;
  }
  .xp-product-name {
    color: #333333;
    font-size: 14px;
    line-height: 1em;
    margin-top: 18px;
  }
  .xp-product-price-area {
    color: @main-color;
    font-size: 20px;
    line-height: 1em;
    margin-bottom: 4px;
    span {
      display: inline-block;
    }
  }
  p {
    margin: 0;
  }
}
