.catalog-product {
  .in-item-basic {
    padding: 1.2rem 1.2rem 1rem;
    .in-item-tag {
      margin-bottom: 0.6rem;
      li {
        border-radius: 0.2rem;
        font-size: 1rem;
        height: 1.7rem;
        line-height: 1.7rem;
        padding: 0 0.6rem;
        text-transform: capitalize;
        &.in-new-icon {
          background: #5db383;
        }
        &.in-sale-icon {
          background: #000;
        }
      }
      li.in-new-icon {
        background: #ff3200;
      }
    }
    .in-item-title {
      margin-bottom: 0.4rem;
    }
    .in-product-price {
      .member-price {
        margin: 12px 0;
        img {
          width: 80%;
        }
      }
      .in-price .price {
        color: #000;
        font-weight: 500;
      }
    }
  }
  .in-item-detail {
    padding-left: 1.2rem;
    .attr_box {
      color: #999999;
      span {
        border: none;
        font-size: 1.3rem;
        margin: 0;
        padding: 0;
        text-transform: capitalize;
        &.attr {
          color: #333333;
        }
      }
    }
    li {
      padding: 0.8rem 0.8rem 0.8rem 0;
      &:first-child {
        align-items: center;
        display: flex;
        height: 4rem;
      }
      .icon {
        color: #979797;
        font-size: 1.8rem;
      }
      h6 {
        color: #333333;
        font-size: 1.6rem;
        text-transform: capitalize;
      }

      .discount {
        flex: none;
        display: flex;
        align-items: center;
      }

      .coupon {
        padding: 2px 8px;
        margin-left: 23px;
        font-size: 12px;
        line-height: 1.2;
        color: #FF3200;
        border: 1px solid #FF3200;
      }
    }
  }
  .in-item-review .in-review-title {
    align-items: center;
    display: flex;
    height: 4.2rem;
    padding: 0 0.8rem 0 1.2rem;
    h2 {
      font-size: 1.6rem;
      span {
        color: #666666;
        margin-left: 0.5rem;
      }
    }
    i {
      color: #979797;
      font-size: 1.8rem;
    }
  }
  .in-pic-preview img {
    object-fit: contain;
  }
  .in-preview .swiper-pagination .swiper-pagination-bullet {
    border-radius: 0.35rem;
    height: 0.7rem;
    margin: 0.4rem;
    width: 0.7rem;
    &-active {
      width: 1.6rem;
    }
  }
  .in-preview-slider {
    padding: 0 0.8rem;
    .swiper-slide {
      .in-item-review {
        margin: 0;
        li         > div {
          align-items: center;
          justify-content: space-between;
          .in-review-user {
            align-items: center;
            display: flex;
            .in-nick {
              color: #999999;
              font-size: 1.2rem;
              margin-right: 0.8rem;
            }
            .in-rating {
              display: flex;
              .icon-raty_null &:before {
                .IN-iconfont("\e6fa");                    color: #ff7817;                    font-size: 1.4rem;
              }
              i {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 1.7rem;
              }
            }
          }
          small {
            font-size: 0.9rem;
          }
        }
        > section {
          .in-review-pics img {
            object-fit: cover;
          }
          h2 {
            margin-bottom: 1rem;
          }
          p.gray {
            color: #666666;
            font-size: 1.1rem;
          }
        }
      }
      > p {
        color: #aaaaaa;
        font-size: 1.3rem;
        padding: 0.8rem 0;
      }
    }
  }
  .in-product-actions {
    line-height: 4.4rem;
    a {
      font-size: 1.4rem;
      letter-spacing: 0.5px;
    }
    i {
      border: none;
      padding: 0 8px;
      width: auto;
      img {
        width: 2rem;
      }
      &.icon-cart &:before {
        .IN-iconfont("\e71b");
      }
      &.icon-headphone &:before {
        .IN-iconfont("\e6fc");
      }
      &.icon-home {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: xmall-icon !important;
        font-size: 2.4rem;
        font-style: normal;
      }
      &.icon-share &:before {
        .IN-iconfont("\e6f7");
      }
      &.icon-wsc &:before {
        .IN-iconfont("\e6f6");
      }
      &.in-wishlist-red &:before {
        .IN-iconfont("\e6f6");          color: #ef2525;
      }
    }
    .wishlist {
      padding: 0 8px;
      img {
        width: 2rem;
      }
    }
    .goods-share {
      img {
        width: 1.6rem;
      }
    }
    .icon-group {
      & >img {
        width: 40px;
        padding: 0 8px;
      }
      & >img:last-of-type {
        padding: 0 10px;
      }
      .icon-cart::before {
        margin-bottom: -2px;
        display: block;
      }
    }
  }
  .in-product-nav {
    border: none;
    .edit_coment i &:before {
      .IN-iconfont("\e6fb");          color: #666666;
    }
    li {
      border: none;
      &:only-child a {
        color: #333333;
      }
      a {
        background: none;
        color: #333333;
        font-size: 1.8rem;
        padding: 0 0.8rem;
        width: auto;
        &.active {
          // color: @c-orange;
        }
      }
    }
  }
  .in-product-reviews-wrapper {
    .in-header-nav {
      border: none;
      i.icon-edit &:before {
        .IN-iconfont("\e6fb");          color: #666666;
      }
    }
    .in-result .in-no-review {
      .icon-null {
        display: none;
      }
      h1 {
        color: #aaaaaa;
        font-size: 1.4rem;
      }
    }
    div[data-modal="reviewList"] > .v--modal {
      background: #f9f9f9;
    }
  }
  .in-rating-wrapper {
    .in-btn {
      height: 4rem;
      .in-reset {
        background: #999;
        color: white;
      }
      a,button {
        line-height: 4rem;
      }
    }
    .in-form {
      padding: 0 1.6rem;
      position: relative;
      .close_icon {
        position: absolute;
        right: 1.3rem;
        top: 1.3rem;
        z-index: 5;
        &:before {
          .IN-iconfont("\e6f7");          transform: rotate(45deg);          color: #999999;          display: block;
        }
      }
      .in-rating {
        .icon-raty_full &:before {
          .IN-iconfont("\e705");            color: #999999;            font-size: 2.1rem;
        }
        .icon-raty_null &:before {
          .IN-iconfont("\e6fa");            color: #ff7817;            font-size: 2.2rem;
        }
        .icon-raty_null:before {
          color: #000;
        }
        i {
          align-items: center;
          display: flex;
          justify-content: center;
          margin: 0 0.1rem;
          width: 2.15rem;
        }
      }
      .in-review-pics {
        .icon-plus {
          align-items: center;
          background: #f3f3f3;
          display: flex;
          height: 6.9rem;
          justify-content: center;
          width: 6.9rem;
        }
        > div {
          height: 6.9rem;
          margin-right: 0.4rem;
          width: 6.9rem;
        }
      }
      .in-review-row {
        border-radius: 1.5rem;
        height: 3rem;
        overflow: hidden;
        padding: 0;
        &.focus {
          border: 1px solid @c-orange;
        }
        &.in-textarea {
          border-radius: 0.4rem;
          height: 7rem;
        }
        input,textarea {
          background: #f3f3f3;
          font-size: 1.3rem;
          padding: 0.8rem;
          &::placeholder {
            color: #999999;
          }
          &:focus {
            background: white;
          }
        }
      }
    }
  }
  .in-slider-footer-container {
    bottom: 4.4rem;
  }

  // xpark shop
  .xpark-shop-info {
    background-size: cover;
    display: flex;
    padding: 14px;

    .logo {
      height: 40px;
      width: 40px;
      margin: 10px 10px 0 0;

      img {
        width: 100%;
      }
    }
    .shop-info {
      .wrapper {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        .detail {
          width: 200px;
          .name {
            color: #fff;
            padding: 10px 0;
            font-size: 15px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .description {
            font-size: 11px;
            color:#999999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .view-shop {
          width: 86px;
          margin-left: 8px;
        }
      }

      .contact {
        display: flex;
        font-size: 11px;
        color: #999999;
        .tel {
          margin-right: 10px;
        }
        img {
          width: 10px;
          vertical-align: inherit;
        }
      }
    }
  }

  // discount modal
  .discount-modal .v--modal{
    position: absolute;
    border-radius: 8px 8px 0 0;
    top: initial !important;
    bottom: 0 !important;
  }

  .select-discount-modal {
    position: relative;
    padding: 20px 24px;
    text-align: center;

    .points__container {
      position: relative;
    }

    .obtained {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      padding: 3px 8px 4px 10px;
      font-size: 10px;
      color: #6C6C6C;
      font-weight: 600;
      background-color: #E3E3E3;
      border-radius: 0 0 0 4px;
    }

    .modal-title {
      font-size: 18px;
      margin-bottom: 40px;
    }

    .close-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 15px;
      top: 15px;
      width: 16px;
      height: 16px;
      padding-top: 2px;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;

      i {
        font-size: 14px;
      }
    }

    .points__item {
      width: 100%;
      height: 90px;
      border: none;
      padding: 8px 15px;
      margin: 0 0 15px 0;
      cursor: inherit;

      &::before,&::after {
        height: 20px;
        width: 20px;
        top: 55px;
      }

      .points__content {
        position: relative;
      }

      .points__quota {
        font-size: 14px;
        color: #111;
        font-weight: bold;
        line-height: 17px;
        margin: 0 0 4px 0;
      }

      .get-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 12px;
        color: #FF3200;
        background-color: #fff;
        padding: 4px 13px;
        border: 1px solid #FF3200;
        border-radius: 13px;
        cursor: pointer;
      }

      .points__date {
        font-size: 12px;
        line-height: 17px;
        color: #999;
      }

      .points__split-line {
        margin: 10px 0 6px;
        border-bottom: 1px dashed #C4C4C4;
      }

      .points__action {
        justify-content: center;
        padding-top: 0;
      }

      .points__need {
        margin: 0;
        font-size: 12px;
        line-height: 17px;
        color: #111;
      }
    }
  }
}
