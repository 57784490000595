.account-information-edit {
  .in-account-nav h2 + a {
    opacity: 0;
    pointer-events: none;
  }
  .in-cart-bar {
    height: 4rem;
    line-height: 4rem;
  }
  .in-information-container {
    border-radius: 0.2rem;
    margin: 0.4rem 0.8rem;
    padding: 0.8rem;
    .in-checkbox-col {
      input {
        display: none;
        &:checked + label:before {
          .IN-iconfont("\e6d3");            font-size: 1rem;            color: white;            display: flex;            width: 1.1rem;            height: 1.1rem;            background: @c-orange;            margin-right: 0.4rem;            justify-content: center;            align-items: center;            font-weight: bold;
        }
      }
      input:checked + label:before {
        background: #000;
      }
      label {
        align-items: center;
        display: flex;
        padding: 0;
        &:before {
          background: #f3f3f3;
          content: "";
          display: block;
          height: 1.1rem;
          margin-right: 0.4rem;
          width: 1.1rem;
        }
      }
    }
    .in-input-row {
      position: relative;
      border-radius: 1.5rem;
      font-size: 1.4rem;
      height: 3rem;
      line-height: 3rem;

      select {
        background-color: #eee;
        border-radius: 1.5rem;
        padding: 0 .8rem;
        color: #333;

        option {
          color: #333;
        }
      }

      .select-label {
        position: absolute;
        font-size: 1.4rem;
        color: #7a7a7a;
      }

      i.icon-arrow-down {
        position: absolute;
        right: 10px;
        font-size: 22px;
        color: #666;
        cursor: pointer;
        pointer-events: none;
      }
    }
  }
}
