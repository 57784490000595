.ship-product-list {
  list-style: disc;
  padding-left: 54px;
  .name,.qty {
    display: block;
    font-size: 14px;
    line-height: 1.2;
  }
  li {
    padding: 10px 0;
  }
}
.ship-to-name {
  border: none !important;
  font-size: 16px;
}
.ship-to-title {
  background: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 0.05rem 0.7rem 0 rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  margin-bottom: 0.8rem;
  padding: 0.8rem;
  .icon-arrow-back {
    font-size: 24px;
    transform: rotate(180deg);
  }
}
