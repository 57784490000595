.list-info {
  border-bottom: 1px solid #eee;
  line-height: 1.2;
  padding: 0.8rem 0;
  &.total-area li {
    justify-content: space-between;
    span:first-child {
      width: auto;
    }
  }
  li {
    align-items: flex-start;
    border: none;
    justify-content: flex-start;
    margin: 14px 0;
    padding: 0 !important;
    word-wrap: break-word;
    span {
      display: block;
      padding: 0 5px;
    }
    span:first-child {
      color: #666;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 22%;
    }
  }
}

.list-info:first-child {
  li {
    span {
      width: 78%;
    }
    span:first-child {
      width: 22%;
      overflow: unset;
    }
  }
}
