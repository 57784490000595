.in-header-container {
  .padding-rem(30px @spacing-fourfold);
  align-items: center;
  background: #1e1e1e;
  display: flex;
  justify-content: space-between;
  .avatar {
    height: 6rem;
    position: relative;
    width: 6rem;
    &.load::before {
      background: #f7f7f7;
      border-radius: 50%;
      border: 5px solid #fff;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .hide {
      display: none;
    }
  }
  .in-account-info {
    .margin-rem(0 @spacing-fourfold);
    color: #fff;
    flex: 1;
    line-height: 1.6em;
    h5 {
      .font-size-rem(32px);
    }
  }
  i {
    .font-size-rem(48px);
    color: @light-gray;
  }
  img {
    .size(4.8rem);
    background: #fff;
    border-radius: 50%;
  }
}
.in-header-menu {
  .box-shadow(0 3px 5px rgba(0, 0, 0, 0.24));
  .centered-line(3rem);
  background: #FFFFFF;
  position: relative;
  z-index: 6;
}
.in-header-nav {
  .padding-rem(@spacing-tripple @spacing-fourfold);
  align-items: center;
  background: #fff;
  border-bottom: 1px solid @border-color;
  display: flex;
  height: 4.5rem;
  justify-content: space-between;
  position: relative;
  z-index: 6;
  a {
    color: @font-color;
  }
  h2 {
    .font-size-rem(40px);
    flex: 1;
    // padding-right: 2.4rem;
    text-align: center;
  }
  i {
    .font-size-rem(48px);
  }
}
.in-header-quick {
  align-items: center;
  background: #000;
  display: flex;
  flex-direction: column;
  height: 8.4rem;
  justify-content: center;
  padding: 0 .8rem;
  position: relative;
  z-index: 10;
  .xp-header-logo {
    align-items: center;
    display: flex;
    height: 4rem;
  }
  .xp-nav-area {
    align-items: center;
    display: flex;
    height: 4.4rem;
    width: 100%;
  }
  i {
    color: #666666;
    vertical-align: middle;
  }
}
