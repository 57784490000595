.in-checkout-content {
  background: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .15);
  .in-password-container {
    .centered-line(3.7em);
    .margin-rem(@spacing-fourfold 0);
    display: flex;
    justify-content: space-between;
    position: relative;
    button {
      // background: @green;
      background: #000;
      border-radius: 2rem;
      color: #fff;
      text-align: center;
      width: 48%;
    }
    div {
      background: @border-color;
      border-radius: 2rem;
      border: 1px solid @input-border-color;
      width: 48%;
    }
    input {
      .padding-rem(0 @spacing-fourfold);
      background: @border-color;
      border-radius: 2rem;
      display: block;
      height: 100%;
      width: 100%;
      &:-webkit-autofill {
        box-shadow: inset 0 0 0 100rem @border-color;
      }
    }
  }
  > div {
    //.padding-rem(0 @spacing-fourfold);
    border-top: 1px solid @border-color;
    &.in-shipping-expenses {
      .padding-bottom-rem(@spacing-fourfold);
    }
    &:first-child {
      border-top: none;
    }
    h6 {
      .font-size-rem(24px);
    }
  }
  h2 {
    .centered-line(1em);
    .font-size-rem(32px);
    .margin-rem(32px 0);
    text-align: center;
    text-transform: uppercase;
  }
}
.in-checkout-index {
  .in-slider-container {
    top: 10.8rem;
  }
  .in-swiper {
    background: #fff;
  }
  .my-card-bag{
    top: 4.5rem;
  }
}
.in-checkout-nav {
  background: #333;
  border-bottom: none;
  a,h2 {
    color: #fff;
  }
}
.in-checkout-payment {
  .in-checkout-content {
    box-shadow: none;
    overflow: hidden;
    .in-payments-container dd img {
      .margin-left-rem(-10px);
      max-height: 60px;
      width: auto;
    }
    > div {
      .padding-bottom-rem(@spacing-fourfold);
    }
    i {
      .font-size-rem(60px);
      .margin-right-rem(@spacing-fourfold);
      color: @green;
      text-align: center;
      width: 4rem;
    }
  }
  .in-coupon-container {
    .centered-line(2.6rem);
    display: flex;
    justify-content: space-between;
    div {
      border-radius: 1.3rem;
      &.in-add-coupon {
        .font-size-rem(40px);
        .margin-left-rem(10px);
        .size(2.6rem);
        background: @green;
        color: #fff;
        text-align: center;
      }
      &.in-input {
        border: 1px solid @dark-border;
        display: flex;
      }
      &.in-submit {
        .margin-left-rem(@spacing-fourfold);
        background: @green;
        color: #fff;
        flex: 1;
        text-align: center;
      }
    }
    div.in-submit {
      background: #000;
    }
    input {
      .centered-line(2.4rem);
      .padding-rem(0 24px);
      background: @border-color;
      border-radius: 1.3rem;
      width: 100%;
    }
  }
  .in-item-container {
    .margin-top-rem(16px);
    background: #fff;
  }
  .in-method-container,.in-shipinfo-container {
    .font-size-rem(18px);
    align-items: center;
    display: flex;
    line-height: 1.5em;
  }
  .in-payments-container {
    dd {
      color: @light-gray;
      line-height: 1.5em;
    }
    dl {
      .margin-bottom-rem(32px);
      padding-left: 4.8rem;
      position: relative;
      &.active:before {
        background: @green;
      }
      &:before {
        .font-size-rem(40px);
        .size(3rem);
        background: @dark-border;
        border-radius: 50%;
        color: #fff;
        content: "\e93e";
        display: block;
        font-family: 'xmall-icon';
        left: 0;
        line-height: 3rem;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    dt {
      .font-size-rem(28px);
      line-height: 1.6em;
    }
  }
  .in-reward-points p {
    .margin-top-rem(20px);
    color: @light-gray;
    line-height: 1.5em;
    padding: 0 10%;
    text-align: center;
  }
}
.in-checkout-process {
  .padding-rem(30px 40px 50px);
  background: #333;
  &:before {
    background: @dark-border;
    content: "";
    display: block;
    height: 1px;
    margin: 0 auto -.7rem;
    width: 66.66%;
  }
  li {
    text-align: center;
    width: 33.33%;
    &.active {
      i {
        background: #fff;
        border-color: @green;
      }
      p {
        color: @green;
      }
    }
    i {
      .size(1.4rem, 1.4rem);
      background: @light-gray;
      border-radius: 50%;
      border: 3px solid @dark-border;
      display: block;
      margin: 0 auto;
    }
    p {
      .centered-line(1rem);
      .margin-top-rem(10px);
      color: @light-gray;
      font-size: .8rem;
      text-transform: uppercase;
    }
  }
  li.active {
    i {
      border-color: #000;
    }
    p {
      color: #000;
    }
  }
  ul {
    display: flex;
    justify-content: space-around;
  }
}
.in-checkout-success a {
  .margin-top-rem(50px);
}
.in-checkout-totals {
  .padding-rem(60px 0);
  line-height: 1.5rem;
  .in-checkout-grandtotal {
    .font-size-rem(18px);
    dd {
      .font-size-rem(24px);
    }
  }
  .in-checkout-subtotal {
    dd {
      .font-size-rem(18px);
      font-weight: normal;
    }
    dt {
      color: @light-gray;
    }
  }
  > p {
    color: @green;
    text-align: center;
  }
  dd {
    .padding-rem(0 12px);
    flex: 1;
  }
  dl {
    display: flex;
    font-weight: bold;
  }
  dt {
    .padding-rem(0 12px);
    flex: 1;
    text-align: right;
  }
}
