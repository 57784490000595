.in-item-basic {
  .margin-rem(@spacing-double 0);
  .padding-rem(@spacing-fourfold);
  align-items: center;
  background: #fff;
  display: flex;
  justify-content: space-between;
  line-height: 1.5rem;
}
.in-item-comments {
  .margin-rem(20px 0);
  .padding-rem(0 20px);
  align-items: center;
  border-left: 1px solid #d3d3d3;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  p {
    line-height: 1.8rem;
  }
  strong {
    color: #900;
    display: block;
    font-size: 1.5rem;
  }
}
.in-item-container {
  .in-title {
    .font-size-rem(24px);
    border-bottom: 1px solid @border-color;
    line-height: 3.5em;
    text-align: center;
  }
  li {
    .padding-rem(@spacing-fourfold);
    // align-items: center;
    border-bottom: 1px solid @border-color;
    // display: flex;
    // justify-content: space-between;
    .in-item-info {
      .margin-rem(0 @spacing-fourfold);
      flex: 1;
      p {
        .font-size-rem(24px);
        .margin-rem(@spacing-double 0);
        line-height: 1.25em;
        .in-item-attr {
          .font-size-rem(20px);
          color: @gray;
        }
      }
    }
    .in-item-pic {
      .size(6rem);
      background-color: @border-color;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      img{
        height: auto;
        max-width: 100%;
        vertical-align: middle;
        width: auto;
        max-height: 100%;
      }
    }
  }
}
.in-item-deposit {
  background: red;
  color: #fff;
  padding: 1.2rem 1.2rem 1rem;
  position: relative;
  div {
    display: inline-block;
  }
}
.in-item-des p {
  .font-size-rem(16px);
  color: @gray;
  line-height: 2rem
}
.in-item-detail {
  .margin-rem(@spacing-double 0);
  background: #fff;
  .in-highlight {
    color: @orange;
  }
  div {
    flex: 1;
    line-height: 1.8rem;
    overflow: hidden;
    span {
      .centered-line(1.6rem);
      .inline-block;
      .margin-right-rem(10px);
      .padding-rem(0 10px);
      border: 1px solid @orange;
      vertical-align: middle;
    }
  }
  h6 {
    .font-size-rem(14px);
    color: @light-gray;
    font-weight: normal;
    text-transform: uppercase;
    width: 6.5rem;
  }
  i {
    color: #ccc;
    display: inline-block;
    transform: rotateZ(180deg);
  }
  li {
    .font-size-rem(18px);
    .padding-rem(@spacing-tripple @spacing-fourfold);
    border-bottom: 1px solid @border-color;
    display: flex;
    justify-content: space-between;
    line-height: 2.5em;
    > a {
      display: flex;
      overflow: hidden;
    }
  }
  p {
    .font-size-rem(24px);
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.in-item-review {
  .margin-rem(@spacing-double 0);
  background: #fff;
  &:last-child {
    margin-bottom: 0;
  }
  .in-review-pics {
    .margin-top-rem(24px);
    overflow: hidden;
    white-space: nowrap;
    div {
      .size(5rem, 5rem);
      background: #e9e9e9;
      display: flex;
      justify-content: center;
      align-items: center;
      .margin-rem(0 20px 10px 0);
    img {
        // background: @border-color;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

  }
  .in-review-title {
    .centered-line(3rem);
    .padding-rem(0 20px);
    border-bottom: 1px solid @border-color;
    display: flex;
    justify-content: space-between;
    h2 {
      .font-size-rem(18px);
      color: @font-color;
      span {
        font-weight: normal;
      }
    }
    i {
      transform: rotateZ(180deg);
    }
  }
  li {
    .padding-rem(30px 20px);
    border-bottom: 1px solid @border-color;
    &:last-child {
      border-bottom: none;
    }
    .gray {
      color: @light-gray;
    }
    .in-review-user {
      .margin-right-rem(20px);
      p {
        white-space: nowrap;
      }
    }
    > div {
      .margin-bottom-rem(20px);
      display: flex;
    }
    h2 {
      .font-size-rem(24px);
      .margin-bottom-rem(10px);
      font-weight: normal;
    }
    i {
      .font-size-rem(30px);
      &.active {
        color: @yellow;
      }
    }
    p {
      .font-size-rem(18px);
      line-height: 1.3em;
      &.in-nick {
        .font-size-rem(20px);
      }
      small {
        .font-size-rem(12px);
      }
    }
  }
}
.in-item-spec {
  .padding-rem(0 @spacing-fourfold);
  background: #fff;
  dd {
    width: 60%;
    word-break: break-word;
  }
  dl {
    .font-size-rem(24px);
    display: flex;
    line-height: 1.5em;
  }
  dt {
    .margin-right-rem(20px);
    width: 40%;
  }
  h2 {
    .font-size-rem(24px);
    .margin-bottom-rem(20px);
  }
  section {
    .padding-rem(40px @spacing-fourfold);
    border-bottom: 1px solid @border-color;
  }
}
.in-item-tag li {
  .centered-line(1.5rem);
  .font-size-rem(12px);
  .inline-block;
  .margin-right-rem(8px);
  .padding-rem(0 8px);
  background: @orange;
  color: #fff;
  text-transform: uppercase;
}
.in-item-title {
  .font-size-rem(30px);
  .margin-bottom-rem(@spacing-double);
  color: @font-color;
}
.in-item-upsell {
  .margin-rem(20px 0);
}
.in-item-usps {
  .margin-rem(@spacing-double 0);
  &:last-child {
    margin-bottom: 0;
  }
}
