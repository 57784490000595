.recommend__products {
  padding: 0 0.8rem;
  &.rm__fill {
    padding: 0;
  }
  .in-ads-product-content {
    padding: 0.8rem 0;
  }
  .in-ads-title h2 {
    font-size: 1.4rem;
    font-weight: 400;
    height: 1.33em;
    letter-spacing: 0.1rem;
    line-height: 1.33em;
    margin: 1.6rem 0 0.8rem;
    text-align: left;
    text-transform: uppercase;
    & ~ a {
      display: none;
    }
  }
}
