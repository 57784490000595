.header-title {
  border-bottom: 1px solid #e3e3e2 !important;
}
.in-topic-container {
  padding: 0 20px;
}

.series-list {
  // overflow-x: auto;
  &::-webkit-scrollbar {
    display: none; /*隐藏滚轮*/
  }
  // width: 100%;
  margin-top: 1.8rem !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  .product-series {
    flex: none;
    padding-right: 0.7rem;
    padding-left: 0.7rem;
    height: 2.4rem !important;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 50px;
    margin-right: 0.45rem;
    display: flex;
    align-items: center;
    .series-title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1rem;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .activeSeries {
    border: 1px solid #ff3200;
    p{
      color: #000 !important;
    }
  }
  &::before{
    content: '';
    width: 2rem !important;
    height: 2.6rem !important;
    position: absolute;
    left: 0;
    margin-top: -0.1rem;
    background: linear-gradient(90deg, #fff 32%, transparent 100%) !important;
  }
  &::after{
    content: '';
    width: 2rem !important;
    height: 2.6rem !important;
    position: absolute;
    right: 0;
    margin-top: -0.1rem;
    background: linear-gradient(-90deg, #fff 32%, transparent 100%) !important;
  }
}
.in-topic-slider {
  margin: 0 0.8rem;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none; /*隐藏滚轮*/
  }

}
.topics-content {
  margin-top: 15px !important;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: #000000;
    display: flex;
    align-items: center;
  }

  .species {
    display: inline-block;
    width: 1.5rem;
    margin: 0 4px;
  }

  .gift {
    display: inline-block;
    width: 1.6rem;
    margin: 0 4px;
  }

  .protrude {
    color: #FFAE35;
  }
}
.series-kind {
  margin: 20px 0 15px;
  display: flex;
  justify-content: space-between;
  .series-mbtitle {
    font-size: 1.3rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .product-selection {
    font-size: 1.1rem;
    display: flex;
    padding-left: 4px;
    .Ranking {
      margin-right: 2rem;
      cursor: pointer;
    }
    .Latest {
      cursor: pointer;
    }
    .activeSelection {
      color: #ff3200;
      font-weight: 700;
    }
  }
}
.product-details {
  margin-top: 0.9rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .detail-left {
    width: 100%;
    min-height: 79px;
    padding: 1rem;
    background: #f3f3f3;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .left-content {
      width: 67%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .detail-content {
        width: 100%;
        max-height: 2.9rem;
        line-height: 1.45rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        a{
          color: #000;
        }
      }
      .product-user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user-avatar {
          height: 1.7rem;
          display: flex;
          align-items: center;
          img {
            width: 1.7rem;
            height: 1.7rem;
            border-radius: 50%;
            margin-right: 0.2rem;
          }
        }

        .like-num {
          font-weight: bold;
        }

        .user-avatar,
        .like-num {
          margin-right: 4px;
        }
      }
    }

    .product-video {
      width: 7.45rem;
      height: 5.6rem;
      position: relative;

      img{
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }

      .time {
        position: absolute;
        right: 5px;
        bottom: 2px;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.2;
      }
    }

    &.no-cover {
      .leftBox {
        width: 100%!important;
        padding: 0;
      }
    }
  }
}


.topic_icon {
  display: inline-block;
  width: 1.2rem;
  margin-right: 6px;
}

.in-fans-align-center {
  display: flex;
  align-items: center;
}

[data-modal="xfans-modal"] .v--modal {
  background-color: transparent;
  box-shadow: none;
}
