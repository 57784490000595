.in-mask {
  background: #F9F9F9;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  transition: all .3s ease-in-out;
  width: 100%;
  z-index: 50;
  .logo-image {
    width: 10rem;
  }
  .menu-area {
    .padding-rem(96px 20px 20px);
    height: auto;
  }
  .menu-container {
    .about-menu-content p {
      text-transform: capitalize;
    }
    .menu-content {
      background-color: #fff;
      border-radius: 4px;
      padding: 0 1rem;
      .menu-currency-area {
        display: flex;
        justify-content: space-between;
        i {
          font-size: 1.7rem;
          transform: rotate(-90deg);
          transition: all 0.2s linear;
        }
      }
      .menu-currency-show i {
        transform: rotate(0);
      }
      a {
        align-items: center;
        color: #333333;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        &.active span {
          color: #333333;
        }
        i {
          color: #979797;
        }
      }
    }
    .menu-title {
      .font-size-rem(24px);
      border-bottom: 0;
      color: #999999;
      padding: 1rem;
      text-transform: uppercase;
      i {
        .font-size-rem(26px);
      }
    }
    p {
      .font-size-rem(26px);
      border-top: 1px solid #E3E3E3;
      padding: 1rem 0;
      &.in-language a {
        color: #999999;
      }
      &:first-of-type {
        border-top: 0;
      }
      i {
        .font-size-rem(26px);
        color: #979797;
      }
      i.in-choose-country-icon {
        .font-size-rem(30px);
      }
    }
  }
  .xp-navbar-container {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
