.points__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.points__item {
  background: #f0f0f0;
  border-radius: 4px;
  border: 2px solid #fff;
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  margin: 10px;
  overflow: hidden;
  padding: 15px;
  position: relative;
  width: 100%;
  &::after {
    background: #fff;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 30px;
    position: absolute;
    right: 0;
    top: 82px;
    transform: translate(50%, -50%);
    width: 30px;
  }
  &::before {
    background: #fff;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 30px;
    left: 0;
    position: absolute;
    top: 82px;
    transform: translate(-50%, -50%);
    width: 30px;
  }
  .points__action {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    &.center {
      justify-content: center;
    }
  }
  .points__date {
    font-size: 14px;
    text-align: center;
  }
  .points__disable {
    background: #fff;
    height: 10px;
    left: 0;
    margin-top: -5px;
    position: absolute;
    right: 0;
    top: 50%;
    transform-origin: 50%;
    transform: rotate(45deg);
  }
  .points__need {
    font-size: 14px;
  }
  .points__quota {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 15px;
    text-align: center;
  }
  .points__split-line {
    border-bottom: 1px dashed #333;
    height: 0;
    margin: 10px 0;
  }
  .points__desc {
    text-align: center;
    padding: 0 12px;
    .description {
      font-size: 12px;
      line-height: 1.2;
    }
  }
}
.points__item.active {
  border: 2px solid #ff6b00;
}
.points__page {
  background: #fff;
  margin: 10px;
}
