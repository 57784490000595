.in-home-banner {
  .size(100%, 47vw);
  overflow: hidden;
  & .swiper-slide & img {
    .size(100%, 47vw);
  }
  .swiper-pagination span {
    background: #FFFFFF;
    border-radius: 8px;
    height: 8px;
    opacity: 1;
    width: 8px;
    &.swiper-pagination-bullet-active {
      background: #000;
      height: 8px;
      width: 20px;
    }
  }
}
.in-home-banner {
  min-height: 47vw;
  height: auto;
}
.in-home-newsletter {
  .padding-rem(50px 0);
  background-color: #060606;
  color: #fff;
  & .in-newsletter {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    button {
      .centered-line(2.4rem);
      .font-size-rem(18px);
      .padding-rem(0 20px);
      background: @main-color;
      border-radius: 2px;
      color: #fff;
      margin-top: 10px;
    }
    input {
      .font-size-rem(16px);
      .padding-rem(10px);
      border-radius: 2px;
      flex: 1;
      height: 2.4rem;
      width: 100%;
    }
  }
  .in-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .in-newsletter button {
    background: #666;
  }
  form {
    padding-left: 15px;
    > p {
      .font-size-rem(16px);
      .padding-rem(10px 0 24px);
    }
  }
  h6 {
    .font-size-rem(24px);
    font-family: RobotoMedium;
    font-weight: 500;
  }
  img {
    flex-grow: 1;
    height: auto;
    max-width: 300px;
    width: 110px;
  }
}
.in-home-service {
  .box-shadow(0 1px 3px rgba(0, 0, 0, .1));
  .margin-top-rem(20px);
  .padding-rem(26px 0);
  background: #fff;
  ul {
    display: flex;
    flex-direction: row;
  }
}
