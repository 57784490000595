.in-shipping-address {
  .padding-rem(0 @spacing-fourfold);
  overflow: hidden;
  .in-btn-group {
    .margin-rem(@spacing-tripple 0);
    justify-content: center;
  }
  li {
    .margin-bottom-rem(@spacing-fourfold);
    .padding-rem(@spacing-fourfold);
    background: @border-color;
    border-radius: 5px;
    border: 1px solid @dark-border;
    &.active {
      border-color:@green;
    }
    &.in-shipAdd {
      align-items: center;
      display: flex;
      justify-content: space-between;
      div {
        .margin-right-rem(@spacing-fourfold);
      }
      i {
        .font-size-rem(48px);
      }
      p {
        word-break: break-all;
        &:first-child {
          .font-size-rem(32px);
          .margin-bottom-rem(@spacing-double);
        }
      }
    }
  }
}
.in-shipping-expenses {
  text-align: center;
  h3 {
    .font-size-rem(30px);
    color:@green;
    font-weight: normal;
    line-height: 1em;
  }
  p {
    .font-size-rem(14px);
    color: @light-gray;
    line-height: 1.5em;
    padding: 0 20%;
  }
}
.in-shipping-information {
  .padding-rem(0 @spacing-fourfold);
  div[class^=in-choose] {
    display: flex;
    flex: 3;
    justify-content: space-between;
    &.in-address-select {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
    }
    span {
      .margin-right-rem(30px);
      flex: 1;
    }
  }
  h3 {
    .font-size-rem(30px);
    .margin-bottom-rem(10px);
    color:@green;
    font-weight: normal;
    line-height: 1em;
    text-align: center;
  }
  i {
    color: @dark-border;
  }
  p {
    .font-size-rem(14px);
    color: @light-gray;
    line-height: 1.5em;
    text-align: center;
  }
}
.in-shipping-method {
  .padding-bottom-rem(@spacing-fourfold);
  li {
    .margin-bottom-rem(@spacing-fourfold);
    .padding-rem(@spacing-fourfold);
    background: @border-color;
    border-radius: 5px;
    border: 1px solid @dark-border;
    display: flex;
    justify-content: space-between;
    line-height: 1.5em;
    padding-left: 3.6rem;
    position: relative;
    &.active:before {
      background: @green;
    }
    &:before {
      .font-size-rem(32px);
      .size(2rem);
      background: @dark-border;
      border-radius: 50%;
      color: #fff;
      content: "\e93e";
      display: block;
      font-family: 'xmall-icon';
      left: .8rem;
      line-height: 2rem;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }
    p &.in-title {
      .font-size-rem(24px);
    }
  }
}
