.in-preview {
  background: #fff;
  & .swiper-pagination & {
    .swiper-pagination-bullet {
      .margin-rem(0 12px);
    }
    .swiper-pagination-bullet-active {
      background: @green;
    }
  }
  .in-swiper .swiper-slide {
    .size(100%, 100vw);
    display: flex;
  }
  .swiper-pagination .swiper-pagination-bullet-active {
    background: #666;
  }
}
