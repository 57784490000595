.account-sales-order-view {
  .grp-txt {
    border-radius: 20px;
    border: 1px solid #ff3200;
    box-sizing: border-box;
    height: auto;
    line-height: 1.2;
    margin-right: 10px;
    padding: 10px 10px;
    width: auto;
    &:-moz-placeholder,&:-ms-input-placeholder,&::-moz-placeholder,&::-webkit-input-placeholder {
      color: black;
      font-weight: bold;
    }

  }
  .in-account-nav {
    border: none;
    h2 {
      font-size: 1.8rem;
      text-transform: uppercase;
      & + a {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  .in-detail-container {
    padding: 0 0.8rem;
    .in-item-container {
      .in-title {
        border-bottom: 1px solid #e3e3e3;
        color: #999999;
        font-size: 1rem;
        line-height: 2.9rem;
        text-align: left;
      }
      > div {
        padding: 0 0.8rem;
      }
      li {
        display: flex;
        align-items: center;
        padding: 0.8rem 0;
        border: none;
        .in-item-info {
          margin: 0 1.3rem 0 0.5rem;
          width: calc(100% - 6rem);
          .in-item-subtotal {
            color: #666666;
          }
        }
        .in-item-qty {
          color: #666666;
          font-size: 1.3rem;
        }
      }
    }
    > div {
      border-radius: 0.3rem;
      box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
      margin: 0.8rem 0;
      > div[class$="-container"] {
        > div {
          > p {
            margin: 0.3rem 0;
          }
          h6 {
            margin: 0.3rem 0;
          }
        }
        > i {
          color: #666666;
          line-height: 3.2rem;
          width: 3.2rem;
          &.icon-cart {
            &:before {
              .IN-iconfont("\e6c9");
            }
            + div .in-Montreal {
              color: #666666;
              font-size: 1.1rem;
            }
          }
          &.icon-payment &:before {
            .IN-iconfont("\e6ff");
          }
          &.icon-user {
            &:before {
              .IN-iconfont("\e6c8");
            }
            + div {
              .in-Montreal {
                color: #999999;
                font-size: 1rem;
              }
              p {
                font-size: 1.1rem;
              }
            }
          }
        }
        h6 {
          font-size: 1.3rem;
        }
      }
      h2 {
        border-bottom: 1px solid #e3e3e3;
        color: #999999;
        font-size: 1rem;
        height: auto;
        line-height: 2.9rem;
        margin: 0 0 0.8rem;
        text-align: left;
      }
    }
    .time-ticker-container {
      padding: .8rem;
      margin: .2rem 0 .8rem;
    }
    .pp-order-ship {
      ul >li {
        display: block;
      }
      .active {
        flex-direction: column;
        align-items: unset;
      }
      .logistic-container {
        .logistic-label span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .card {
          i {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .in-ram-content {
    .in-order-container {
      padding: 0.8rem;
      > ul > li {
        border-radius: 0.3rem;
        box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
        margin: 0 0 0.8rem;
        .in-history-item {
          border-bottom: 1px solid #e3e3e3;
          border-top: 1px solid #e3e3e3;
          margin: 0 0.8rem;
          padding: 0.8rem 0;
          .in-item-info {
            margin: 0 1.3rem 0 0;
          }
          .in-item-qty {
            color: #666666;
            font-size: 1.3rem;
          }
          .sku {
            color: #666666;
            font-size: 1.1rem !important;
          }
        }
        > P {
          border: none;
          &:first-child {
            padding: 0.95rem 0.8rem;
          }
          &:last-child > span {
            color: #666666;
            font-size: 1.1rem;
          }
        }
      }
    }
    .in-slider-header-container {
      top: 4.5rem;
    }
    .v--modal {
      background: #f9f9f9;
    }
  }
  .in-rma-details-container .in-details-container {
    margin: 0.8rem;
    .in-rma-product-container {
      padding: 0 0.8rem;
      .in-rma-product &:last-child {
        border: none;
      }
    }
    .subTitle {
      color: #aaaaaa;
      font-size: 1rem;
      margin: 0 0.8rem;
      padding: 0.95rem 0;
    }
    > div {
      background: white;
      border-radius: 0.3rem;
      box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
      margin: 0.8rem 0;
    }
  }
  .logistic-container {
    border-top: 1px dashed #ccc;
    padding-top: 20px;
    button {
      border: 0;
    }
  }
  .logistic-label i {
    color: #ff3200;
    float: right;
    font-size: inherit;
    font-style: normal;
  }
}
