.in-result {
  align-items: center;
  bottom: 0;
  display: block;
  justify-content: center;
  left: 0;
  padding: 100px 0;
  position: relative;
  right: 0;
  text-align: center;
  top: 0;
  a {
    color: #000;
    text-transform: uppercase;
    &.in-result-btn {
      .centered-line(3.3em);
      .font-size-rem(18px);
      .inline-block;
      .padding-rem(0 50px);
      background: #fff;
      border-radius: 2em;
      box-shadow: 0 0 20px rgba(0, 0, 0, .05);
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  h1 {
    .font-size-rem(32px);
    .margin-rem(20px 40px);
    color: #111;
    line-height: 1.4em;
  }
  i {
    .font-size-rem(110px);
    color: @green;
  }
  p {
    .font-size-rem(18px);
    color: @light-gray;
    line-height: 1.5em;
    margin: 1rem;
  }
  strong {
    .font-size-rem(80px);
    color: #000;
  }
}
