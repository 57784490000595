.in-cart-bar {
  .centered-line(3.6rem);
  background: #fff;
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 100;
  a,button {
    .font-size-rem(32px);
    background-color: #000;
    // background: @btn-base-color;
    color: #fff;
    display: block;
    flex: 1;
    text-align: center;
    text-transform: uppercase;
  }
  div {
    flex: 1;
    text-align: center;
    &.in-total {
      .font-size-rem(16px);
      color: @light-gray;
    }
    .price {
      .font-size-rem(24px);
      color: @font-color;
    }
  }
}
