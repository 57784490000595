.in-net-goods-container {
  padding-top: 10px;
  .date-filter-container .action-button {
    background-color: #000;
  }
  .in-bank.net-container .subTitle {
    font-size: 1.3rem;
  }
  .in-btn {
    margin: .8rem 0;
  }
  .net-container {
    margin-bottom: 50px;
  }
  .subTitle {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 20px 0;
  }
}
.in-net-goods-container-out .in-slider-footer-container {
  bottom: 0!important;
}
