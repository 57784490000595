.in-mpesa-container .in-slider-container {
  background: #fff;
  bottom: 0;
}
.in-mpesa-content {
  background: #fff;
  .in-confirm {
    .padding-rem(40px);
    text-align: center;
    a {
      .inline-block();
    }
  }
  .in-mpesa-total {
    .font-size-rem(24px);
    .padding-rem(0 @spacing-fourfold);
    align-items: center;
    background: url('../images/mpesa.jpg') no-repeat .8rem center;
    border-bottom: 1px solid @border-color;
    display: flex;
    height: 8rem;
    justify-content: flex-end;
    strong {
      .font-size-rem(36px);
      color: @green;
    }
  }
  .in-notify {
    .padding-rem(@spacing-fourfold);
    border-bottom: 1px solid @border-color;
    p {
      .padding-rem(@spacing-fourfold);
      background: #f4fff0;
      border: 1px solid #5ed03b;
      color: #1e7415;
      line-height: 1.5em;
    }
  }
  ul {
    .padding-rem(@spacing-fourfold);
    border-bottom: 1px solid @border-color;
    list-style-position: inside;
    list-style-type: disc;
    em {
      color: #f00;
      font-style: normal;
    }
    li {
      color: @gray;
      line-height: 2.5em;
    }
  }
}
