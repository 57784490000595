.in-information-container {
  .padding-rem(30px 15px);
  background: #fff;
  box-shadow: 0 3px 5px rgba(0,0,0,.15);
  overflow: hidden;
  .date-filter-container {
    .action-button {
      background-color: #000;
      color: #fff;
      height: 40px;
      margin-bottom: 20px;
      margin-top: 10px;
      width: 100%;
    }
    .field-row {
      & > label {
        color: #333;
        display: block;
        margin-bottom: 5px;
      }
      .vdp-datepicker {
        margin-bottom: 15px;
      }
      input {
        border: 1px solid #ccc;
        height: 36px;
        padding: 0 8px;
        width: 100%;
      }
    }
  }
  .in-form .in-info-row {
    margin-bottom: 15px;
    input {
      border: 1px solid #ccc;
      height: 40px;
      padding: 0 12px;
      width: 100%;
    }
  }
  .in-info-row.input-disable {
    background: #999;
    input {
      background: #999;
    }
  }
  .in-table.report-table {
    li {
      border-bottom: 1px solid #eee;
      display: flex;
      padding: 10px 0;
      width: 200%;
    }
    span {
      display: inline-block;
      flex-grow: 1;
      text-align: center;
      vertical-align: top;
      width: 16.6%;
    }
  }
  .no-data {
    padding: 20px;
    text-align: center;
  }
  .subTitle {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
