.in-rating-wrapper {
  .in-btn-group {
    .margin-bottom-rem(20px);
  }
  .in-form {
    .padding-rem(0 20px);
  }
  .in-rating {
    border: none;
    justify-content: flex-start;
    padding: 0;
    i {
      .font-size-rem(60px);
      &.active {
        color: @yellow;
      }
    }
  }
  textarea {
    border: none;
    line-height: 1.5rem;
    outline: none;
  }
}
