.btn--primary {
  background: #000;
  color: #fff;
  display: block;
  font-size: 1.6rem;
  height: 4rem;
  line-height: 4rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  z-index: 100;
}
.btn-disabled {
  background: #777 !important;
}
.btn-group.center,.in-cart-bar.center {
  button:first-child {
    margin-right: 5px;
  }
  button:last-child {
    margin-left: 5px;
  }
}
.btn-reset {
  height: auto;
  width: auto;
  button {
    border-radius: 6px;
    font-size: 14px;
    height: auto;
    line-height: 1;
    padding: 10px 20px;
    width: auto;
  }
}
.btn-small {
  background-color: #ff6b00;
  border-radius: 24px;
  border: 0;
  color: #fff;
  height: 45px;
  line-height: 45px;
  padding: 0 24px;
  width: auto;
}
.in-btn {
  .centered-line(3.6rem);
  display: flex;
  width: 100%;
  a,button {
    .font-size-rem(32px);
    background: #000;
    color: #fff;
    flex: 1;
    text-align: center;
    &.in-disabled {
      background: @border-color;
      color: #b0b0b0;
    }
    &.in-pre-order {
      background: @red;
    }
    &.in-reset {
      background: @border-color;
      color: @font-color;
    }
  }
}
.in-btn-group {
  .font-size-rem(24px);
  display: flex;
  &.in-large {
    .font-size-rem(28px);
    justify-content: center;
  }
  .xp-timer-btn {
    .padding-rem(7px 24px);
    background-color: @main-color;
  }
  a {
    .padding-rem(@spacing-tripple @spacing-fourfold);
    align-items: center;
    background: #000;
    border-radius: 2rem;
    color: #fff;
    display: flex;
    &.in-gray {
      background: @dark-border;
    }
  }
}
.in-btn-register {
  .centered-line(4rem);
  .font-size-rem(24px);
  .margin-bottom-rem(@spacing-fourfold);
  background: #000;
  border-radius: 2rem;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
}
.in-btn.in-btn-radius {
  border-color: #000;
  border-radius: 25px;
  // border: 1px solid rgba(255,107,0,1);
  border: 1px solid #000;
  color: #000;
  display: inline-block;
  font-size: 14px;
  height: auto;
  line-height: 1;
  padding: 10px 20px;
  width: auto;
}
