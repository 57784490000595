.account-sales-order-history {
  .in-account-nav {
    border: none;
    h2 {
      font-size: 1.8rem;
      text-transform: uppercase;
      & + a {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  .in-header-menu {
      overflow: hidden;
  }
  .in-order-container {
    padding: 0.8rem;
    margin-top: 3rem;
    > ul > li {
      border-radius: 0.3rem;
      box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
      margin: 0 0 0.8rem;
      .in-history-item {
        border-bottom: 1px solid #e3e3e3;
        border-top: 1px solid #e3e3e3;
        margin: 0 0.8rem;
        padding: 0.8rem 0;
        position: relative;
        .crowd-founding {
            position: absolute;
            right: 0;
            top: 0.8rem;
            padding: 2px 5px;
            border-radius: 5px;
            background: #ff3200;
            color: white;
            font-weight: bold;
        }
        .in-item-info {
          margin: 0 1.3rem 0 0.5rem;
        }
        .in-item-qty {
          color: #666666;
          font-size: 1.3rem;
        }
        .item_sku {
          display: none;
        }
        .in-item-pic {
          background-color: @border-color;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          img{
            height: auto;
            max-width: 100%;
            vertical-align: middle;
            width: auto;
            max-height: 100%;
          }
        }
      }
      > P {
        border: none;
        &:first-child {
          padding: 0.95rem 0.8rem;
        }
        &:last-child > span {
          color: #666666;
          font-size: 1.1rem;
        }
      }
    }
  }
  .in-slider-header-container {
    top: 4.5rem;
  }
}
