.in-blog-detail-container {
  .margin-rem(@spacing-double @spacing-double 0);
  .padding-rem(0 @spacing-fourfold);
  background: #fff;
  .in-blog-content {
    .padding-rem(@spacing-fourfold 0);
  }
  h1 {
    .font-size-rem(30px);
    .margin-bottom-rem(@spacing-fourfold);
    .padding-rem(@spacing-fourfold 0);
    border-bottom: 1px solid @border-color;
    font-weight: normal;
    line-height: 1em;
  }
}
.in-blog-list {
  .padding-rem(@spacing-double);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h1 {
    .font-size-rem(32px);
    color: #000;
    line-height: 1.25em;
  }
  img {
    .margin-bottom-rem(15px);
    .size(100%, 43vw);
  }
  li {
    .margin-bottom-rem(@spacing-double);
    .padding-rem(@spacing-fourfold);
    background: #fff;
    box-shadow: 1px 1px 40px rgba(0, 0, 0, .2);
    width: 100%;
  }
  p {
    color: @light-gray;
    line-height: 1.25em;
  }
}
.in-blog-view .swiper-slide {
  min-height: 100%;
}
