.picker {
  background-color: rgba(37,38,45,0.4);
  font-size: 14px;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 200;
  .picker-footer {
    background: @green;
    bottom: 0;
    display: flex;
    height: 3.6rem;
    left: 0;
    line-height: 3.6rem;
    position: absolute;
    right: 0;
    z-index: 66;
    a {
      background: #000;
      color: #fff;
      flex: 1;
      font-size: 1.6rem;
      text-transform: uppercase;
    }
  }
  .picker-panel {
    background: #fff;
    bottom: 0;
    height: 273px;
    position: absolute;
    width: 100%;
    z-index: 600;
    .picker-choose {
      color: #999;
      height: 60px;
      position: relative;
      .cancel {
        font-size: 14px;
        left: 0;
        line-height: 1em;
        padding: 16px;
        position: absolute;
        top: 6px;
        visibility: hidden;
      }
      .cancel:active {
        color: #c2c2c2;
      }
      .confirm {
        color: #007bff;
        font-size: 14px;
        line-height: 1em;
        padding: 16px;
        position: absolute;
        right: 0;
        top: 6px;
      }
      .confirm:active {
        color: #5aaaff;
      }
      .picker-title {
        color: #333;
        font-size: 18px;
        font-weight: normal;
        line-height: 60px;
        margin: 0;
        text-align: center;
      }
    }
    .picker-content {
      position: relative;
      .mask-bottom {
        -webkit-transform: translateZ(0);
        background: linear-gradient(to bottom, rgba(255,255,255,0.4), rgba(255,255,255,0.8));
        bottom: 1px;
        height: 68px;
        pointer-events: none;
        position: absolute;
        transform: translateZ(0);
        width: 100%;
        z-index: 10;
      }
      .mask-top {
        -webkit-transform: translateZ(0);
        background: linear-gradient(to top, rgba(255,255,255,0.4), rgba(255,255,255,0.8));
        height: 68px;
        pointer-events: none;
        position: absolute;
        top: 0;
        transform: translateZ(0);
        width: 100%;
        z-index: 10;
      }
    }
    .wheel-wrapper {
      display: flex;
      padding: 0 16px;
      .wheel {
        -ms-flex-preferred-size: 1e-9px;
        -ms-flex: 1 1 1e-9px;
        -webkit-box-flex: 1;
        -webkit-flex-basis: 1e-9px;
        -webkit-flex: 1;
        flex-basis: 1e-9px;
        flex: 1;
        font-size: 1.2rem;
        height: 173px;
        overflow: hidden;
        width: 1%;
        .wheel-scroll {
          line-height: 36px;
          list-style: none;
          margin-top: 68px;
          padding: 0;
          .wheel-item {
            color: #333;
            height: 36px;
            list-style: none;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .picker-panel.picker-move-enter {
    -webkit-transform: translate3d(0, 273px, 0);
    transform: translate3d(0, 273px, 0);
  }
  .picker-panel.picker-move-enter-active {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .picker-panel.picker-move-leave-active {
    -webkit-transform: translate3d(0, 273px, 0);
    -webkit-transition: all 0.3s ease-in-out;
    transform: translate3d(0, 273px, 0);
    transition: all 0.3s ease-in-out;
  }
}
.picker-render-view .select[data-v-67fafd4e] {
  background: #fff;
  border: 1px solid #e5e5e5;
  height: 40px;
  line-height: 40px;
  margin: 0 10px 40px 10px;
  text-align: center;
}
.picker.picker-fade-enter-active {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.picker.picker-fade-leave-active {
  -webkit-transition: all 0.3s ease-in-out;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
