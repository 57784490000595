@media (max-height: 480px) {
  .index-list {
    .index-list-nav > ul > li {
      padding-top: 3px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .in-productList-container {
    & .in-product-list {
      .padding-left-rem(20);
      .padding-right-rem(20);
    }
  }
}
@media screen and (min-width: 1440px) {
  .in-productList-container {
    & .in-product-list {
      .padding-left-rem(80);
      .padding-right-rem(80);
    }
  }
}
@media screen and (min-width: 300px) {
  body {
    min-width: 300px;
  }
  html {
    font-size: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width: 360px) {
  .in-bottom-nav {
    p {
      -webkit-transform: scale(.9);
    }
  }
}
@media screen and (min-width: 360px) {
  html {
    font-size: 12px;
  }
}
@media screen and (min-width: 360px) {
  .xp-product-href-area {
    .in-flag-wrapper span {
      font-size: 14px;
    }
    .xp-product-max-price {
      font-size: 14px;
    }
    .xp-product-name {
      font-size: 16px;
    }
    .xp-product-price-area {
      font-size: 22px;
    }
  }
}
@media screen and (min-width: 375px) {
  .in-home-newsletter {
    .in-newsletter {
      flex-direction: row;
      button {
        border-radius: 0 2px 2px 0;
        margin-top: 0;
      }
      input {
        border-radius: 2px 0 0 2px;
      }
    }
    img {
      margin-left: 10px;
    }
  }
}
@media screen and (min-width: 400px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 480px) {
  .xp-container {
    padding-left: 13px;
    padding-right: 13px;
  }
}
@media screen and (min-width: 480px) {
  .in-ads-title {
    a {
      right: 15px;
    }
  }
  .xp-product-href-area {
    .in-flag-wrapper span {
      font-size: 16px;
    }
    .xp-product-max-price {
      font-size: 16px;
    }
    .xp-product-name {
      font-size: 20px;
    }
    .xp-product-price-area {
      font-size: 27px;
    }
  }
}
@media screen and (min-width: 510px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 570px) {
  html {
    font-size: 18px;
  }
}
@media screen and (min-width: 640px) {
  html {
    font-size: 20px;
  }
}
@media screen and (min-width: 640px) {
  .xp-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (min-width: 640px) {
  .in-ads-title {
    a {
      right: 20px;
    }
  }
  .xp-product-href-area {
    .in-flag-wrapper span {
      font-size: 18px;
    }
    .xp-product-max-price {
      font-size: 20px;
    }
    .xp-product-name {
      font-size: 26px;
    }
    .xp-product-price-area {
      font-size: 34px;
    }
  }
}
@media screen and (min-width: 700px) {
  html {
    font-size: 22px;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 24px;
  }
}
@media screen and (min-width: 768px) {
  .in-service-list {
    p {
      .font-size-rem(20px);
    }
  }
}
