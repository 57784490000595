.in-sort-by i {
  .inline-block();
  transition: all .3s ease-in-out;
  &.active {
    transform: rotate(180deg);
  }
}
.in-sort-by-container {
  background: rgba(0, 0, 0, .8);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 4.5rem;
  width: 100%;
  .in-container {
    .padding-rem(10px 30px);
    background: #fff;
    display: none;
  }
  .in-sort-list {
    width: 100%;
    li {
      .centered-line(2.5rem);
      .inline-block;
      .padding-left-rem(30px);
      width: 50%;
      &.active span {
        border-bottom: 1px solid @green;
        // color: @green;
        color: #999;
      }
    }
  }
}
