* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
body {
  -webkit-tap-highlight-color: transparent;
  background: #F9F9F9;
  color: @font-color;
  line-height: 1;
  max-height: 100%;
  overflow-y: hidden;
  width: 100%;
}
body.in-menu-open {
  .in-choose-country {
    display: none;
    transform: translateX(100%);
    .in-choose-country-text {
      align-items: center;
      display: flex;
      img {
        margin-right: 16px;
        width: 2.4rem;
      }
    }
  }
  .in-mask {
    transform: translateX(0);
  }
}
button,input {
  border: none;
  font-family: RobotoRegular;
  outline: none;
}
dd,dl,dt,p {
  margin: 0;
  padding: 0;
}
div.in-choose-attrs li {
  .margin-right-rem(10px);
}
div.time-ticker-container {
  display: inline-block;
  font-size: 1rem;
  padding: 0;
  position: absolute;
  right: 65px;
  span.time-ticker {
    border-radius: 5px;
    font-weight: bold;
    padding: 2px 5px;
    &.green {
      border-color: green;
      color: green;
    }
    &.red {
      border-color: red;
      color: red;
    }
    &.yellow {
      border-color: #ff6b00;
      color: #ff6b00;
    }
  }
}
footer {
  .box-shadow(0 -1px .5rem rgba(0, 0, 0, 0.2));
  background: white;
  bottom: 0;
  height: 5.2rem;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 6;
}
h1,h2,h3,h4,h5,h6 {
  font-family: RobotoRegular;
  font-weight: normal;
  letter-spacing: .05rem;
  margin: 0;
  padding: 0;
}
header {
  background: #fff;
  position: relative;
}
html {
  -webkit-tap-highlight-color: transparent;
  font-family: RobotoRegular;
  font-size: 20px;
  line-height: 1;
}
img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
  &[lazy="loading"] {
    .size(3rem);
    margin: auto;
  }
}
img:not([src]),img[src=""] {
  opacity:0;
}
input:-moz-placeholder,input:-ms-input-placeholder,input::-moz-placeholder,input::-webkit-input-placeholder {
  color: #CCC;
}
input:-webkit-autofill,select:-webkit-autofill,textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  background-image: none;
}
input[name="reviewsImg"] {
  height: 0;
  visibility: hidden;
}
input[type="checkbox"] {
  height: 20px;
  width: 20px;
}
ol,ul {
  .reset-list-style();
  margin: 0;
  padding: 0;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: none;
  cursor: pointer;
  font-family: RobotoRegular;
  outline: none;
  width: 100%;
  &:active,&:focus {
    border: none;
    outline: 0;
  }
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
textarea {
  font-family: RobotoRegular;
}
