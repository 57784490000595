.in-coupon-text {
  .font-size-rem(30px);
  .margin-rem(30px 20px);
  text-align: center;
  text-transform: uppercase;
}
.in-coupon-wrapper .in-coupon-item {
  .margin-rem(20px);
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  min-height: 7.5rem;
  overflow: hidden;
  position: relative;
  &.active {
    &:after {
      .size(2rem, 2rem);
      background: @green;
      color: #fff;
      content: "\e93e";
      display: block;
      font-family:"xmall-icon";
      line-height: 2rem;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
    }
    &:before {
      border-radius: 4px;
      bottom: 0;
      box-shadow: inset 0 0 0 3px @green;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .in-coupon-desc {
    .padding(20px);
    align-items: center;
    display: flex;
    flex: 3;
    justify-content: flex-start;
    p {
      color: @light-gray;
      line-height: 1.4em;
    }
  }
  .in-coupon-detail {
    background-color: @khaki;
    color: @brown;
    display: flex;
    flex: 2;
    text-align: center;
  }
  .in-wavy-line {
    background-color: transparent;
    background-image: -moz-radial-gradient(white, white 2px, #d9c387 3px, #d9c387);
    background-image: -webkit-radial-gradient(white, white 2px, #d9c387 3px, #d9c387);
    background-image: radial-gradient(white, white 2px, #d9c387 3px, #d9c387);
    background-size: 8px 8px;
    width: 4px;
  }
  h2 {
    .font-size-rem(32px);
    margin: auto;
  }
}
