.in-preorder-item {
  .margin-rem(20px);
  background: #fff;
  box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
  overflow: hidden;
  .in-btn-group {
    .margin-rem(30px 0);
    justify-content: center;
    a {
      text-transform: uppercase;
      &.in-disabled {
        background: @border-color;
        color: @light-gray;
      }
    }
  }
  .in-preorder-detail {
    border-top: 1px solid @border-color;
    p {
      .font-size-rem(16px);
      .margin-rem(20px);
      color: @light-gray;
      line-height: 1.25em;
    }
  }
  .in-preorder-summary {
    .padding-rem(20px);
    align-items: center;
    display: flex;
    justify-content: space-between;
    h6 {
      .font-size-rem(24px);
      .padding-right-rem(20px);
      flex: 5;
      font-weight: normal;
      line-height: 1.25em;
    }
    p {
      .font-size-rem(24px);
      color: @green;
      flex: 3;
      text-align: right;
      .in-original-price {
        .font-size-rem(20px);
        color: @light-gray;
      }
    }
  }
  .in-timer {
    .margin-rem(40px 0);
    display: flex;
    justify-content: center;
    li {
      .padding-rem(0 20px);
      border-right: 1px solid @border-color;
      text-align: center;
      &:last-child {
        border-right: none;
      }
      span {
        .font-size-rem(36px);
        display: block;
        &.in-time-subtitle {
          .font-size-rem(14);
          color: @light-gray;
        }
      }
    }
  }
}
