.in-shopping-cart-content {
  .margin-bottom-rem(@spacing-fourfold);
  .in-action {
    display: flex;
    i {
      .font-size-rem(48px);
      margin: auto;
    }
  }
  .in-item-qty {
    .centered-line(2.4rem);
    display: flex;
    i {
      background: @border-color;
      border-radius: 50%;
      color: @light-gray;
      text-align: center;
      &.in-decrease {
        margin-right: -1.2rem;
        position: relative;
      }
      &.in-increase {
        .font-size-rem(30px);
        margin-left: -1.2rem;
      }
    }
    input {
      background: @border-color;
      border: none;
      color: #000;
      outline: none;
      text-align: center;
      width: 6rem;
    }
  }
  > div {
    padding: 0;
  }
}
.in-shopping-cart-nav {
  background: transparent;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index:6;
  a {
    color: @font-color;
  }
  h2 {
    .font-size-rem(40px);
    flex: 1;
    text-align: center;
    text-transform: uppercase;
  }
  i {
    .font-size-rem(48px);
  }
}
