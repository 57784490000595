.in-xn-login {
  background: #000 url('../images/bind-bg.png') no-repeat top center;
  background-size: 100% auto;
  color: #fff;
  padding: 0 !important;
  height: 100vh;
  overflow-y: scroll !important;
  position: relative;

  .in-xn-login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .in-msg {
      margin-top: 5px;
      color: red;
      font-size: 1.2rem;
    }

    p {
      text-align: left;
      .margin-top-rem(30);
      line-height: 1.6rem;
      font-size: 1rem;
    }

    .in-account-nav {
      background: none;
      border: none;

      i {
        color: #fff;
      }
    }

    .in-header-nav {
      background: none;
      border: none;
    }

    .head {
      width: 100%;
      height: 20vh;
      align-items: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin-top: 15px;

      .xn-login-title {
        font-size: 1.4rem;
        margin-bottom: 20px;
      }

      img {
        width: 50%;
      }

    }

    .in-xn-login-form {
      width: 100%;
      height: 100%;
      padding: 15px 4%;
      display: flex;
      flex-direction: column;

      a {
        color: #fff;
        font-weight: bolder;
      }

      &__text-content-bg {
        padding: 3px;
        background: #FF3200
          linear-gradient(134deg, #FC3100 0%,
          rgba(255, 255, 255, 0.75) 49%, #00FBFB 100%);

        .text-content {
          margin: 0 auto;
          background-color: #222;
          padding: 10px;

          .xn-login-text-one {
            margin: 0;
          }
        }
      }

      .form-text {
        font-size: 1.2rem;
        text-align: center;
      }

      .form-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .form-info {
          width: 100%;
          display: flex;
          align-items: center;

          .info {
            margin-left: 10px;
          }

          section {
            > div {
              margin: 6px 0;
            }
          }

          .check {
            position: relative;

            .check_box {
              position: absolute;
              visibility: hidden;
              margin-right: 5px;
            }

            .check_box+label {
              display: inline-block;
              width: 1.5rem;
              height: 1.5rem;
              background: url('../images/uncheck.png') no-repeat center;
              background-size: 100%;
            }

            .check_box:checked+label {
              background: url('../images/checked.png') no-repeat center;
              background-size: 100%;
            }
          }

          .data {
            border-top: 1px solid #666;
            padding: 8px 0;
            flex: 1;
            margin-left: 10px;
          }
        }

        .form-info:nth-of-type(1) {
          .data {
            border: none;
          }
        }

        .blank-account {
          text-align: center;
        }

        .form-pwd {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 20px;
          padding: 0 10px;

          &__input {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #fff;

            > img {
              width: 5%;
              padding: 10px 0;
            }

            input {
              flex: 1;
              padding-top: 5px;
              outline: none;
              border: none;
              background: none;
              color: #fff;
              margin: 0 8px;
            }

            input::placeholder {
              color: #fff;
              font-size: 1.2rem;
            }

            .warn-pic {
              width: 6%;
              background: url('../images/wrong-pwd.png') no-repeat center;
              background-size: contain;
            }

            .eye-pic {
              width: 6%;
              background: url('../images/eye.png') no-repeat center;
              background-size: contain;
              margin: 8px 0 0 8px;
            }
          }

          .submit {
            padding: 10px;
            outline: none;
            border: none;
            background-color: #fff;
            margin-top: 15px;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

}
