.account-wishlist {
  .in-account-nav {
    a {
      display: block;
    }
    h2 {
      font-size: 1.8rem;
    }
  }
  .in-wishlist-container {
    padding: 0 0.8rem;
    .in-edit {
      border: none;
      flex: 0 0 2.3rem;
      > div {
        align-items: center;
        border-color: #e3e3e3;
        flex: 0 0 2.8rem;
        height: 2.8rem;
        justify-content: center;
        a {
          display: block;
          height: 100%;
        }
        i {
          display: flex;
          height: 100%;
          justify-content: center;
          margin: 0;
          &.icon-cart {
            align-items: flex-start;
            &:before {
              .IN-iconfont("\e6c9");              font-size: 2.1rem;
            }
          }
          &.icon-delete {
            align-items: flex-end;
            &:before {
              .IN-iconfont("\e703");              font-size: 2rem;
            }
          }
        }
      }
    }
    .in-info {
      flex-direction: column;
      padding: 0 0.5rem;
      .in-flag-wrapper {
        padding: 0;
        position: static;
        > span {
          border-radius: 0.15rem;
          font-size: 0.9em;
          line-height: 1.3rem;
          padding: 0 0.8rem;
          &.in-new-icon {
            // background: #5db383;
            background: #333;
          }
        }
      }
      .in-item-info {
        margin: 0;
        h2 {
          font-size: 1.1rem;
        }
      }
    }
    > li {
      border-radius: 0.3rem;
      box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
      margin: 0.8rem 0;
      padding: 0.8rem;
    }
  }
}
