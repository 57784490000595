.verification-page {
  height: calc(100vh - 13.6rem);
  width: 100vw;
  background: #f5f6f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verification-container {
  width: 92.778vw;
  height: 76.25vw;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .in-verification-text {
    text-align: center;
    font-size: 5vw;
    line-height: 1.25;
    margin-bottom: 5.556vw;
    font-family: 'RobotoRegular';

  }

  .in-otp-box {
    width: 72.167vw;
    height: 11.111vw;
    border-radius: 0;
    font-size: 3.889vw;
  }

  span {
    font-family: 'RobotoRegular';
    font-size: 3.889vw;
    line-height: 1.25;
    position: absolute;
  }

  .in-otp-box.in-text {
    border: 1px solid #000000;
    color: #000000;
    text-align: center;
    font-family: 'RobotoRegular';
    font-weight: 500;
    margin-bottom: 3.472vw;
  }

  .in-otp-box.in-text.alert {
    border: 1px solid #ff3200;
  }

  .in-alert-text {
    color: #ff3200;
    margin: 0 0 3.472vw 0;
    font-family: 'RobotoRegular';
    font-size: 3.889vw;
    text-align: center;
    line-height: 1.25;
  }

  .in-otp-box.in-btn {
    background: #ff3200;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    span.in-submit {
      color: #ffffff
    }

  .in-otp-box.in-btn.pressed {
    background: #ffffff;
    border: 1px solid #ff3200;
    pointer-events: none;
    span.in-submit {
      color: #ff3200;
    }
  }
}
