.in-category-list-content {
  .in-slider-container {
    bottom: 0;
    top: 0;
  }
  .swiper-slide {
    height: auto;
  }
}
.in-category-nav {
  position: relative;
  width: 100%;
  z-index: 9;
  .in-search {
    .margin-rem(0 @spacing-fourfold);
    flex: 1;
    &:last-child {
      margin-right: 0;
    }
    input {
      .padding-rem(0 @spacing-fourfold);
      background: transparent;
      height: 3.2rem;
    }
  }
}
.in-category-tab {
  .centered-line(3rem);
  background: #fff;
  position: relative;
  z-index: 6;
  .swiper-slide {
    width: 25vw;
  }
  a {
    .inline-block();
    color: #000;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
    width: 100%;
    &.active {
      border-bottom: 2px solid @green;
      color: @green;
      height: 3rem;
    }
  }
  li {
    .inline-block;
    width: 25vw;
  }
  ul {
    white-space: nowrap;
  }
}
