.in-group-purchase-container {
  .in-order-tab {
    top: auto;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 6;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 4.5rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: white;
    .in-preorder-tab {
        width: 100%;
    }
  }
  .in-result &.in-with-banner {
    top: 47vw;
  }
  .in-slider-header-container {
    top: 7.5rem;
  }
}
.in-group-purchase-wrapper {
  .padding-rem(0 @spacing-fourfold);
  overflow: hidden;
  text-align: center;
  .in-btn-group {
    .margin-rem(@spacing-fourfold 0);
    justify-content: center;
  }
  .in-group-content {
    .size(100%, 13rem);
    display: flex;
    position: relative;
    .in-group-desc {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      width: 100%;
      z-index: 1;
    }
    img {
      height: 100%;
      width: auto;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      .font-size-rem(14px);
      line-height: 1.3em;
    }
  }
  h6 {
    .font-size-rem(24px);
    line-height: 2.25em;
    text-transform: uppercase;
  }

  p.in-group-price {
    .font-size-rem(36px);
    .margin-bottom-rem(10px);
    line-height: 2em;
  }
  del {
    .font-size-rem(20px);
    color: #999;
  }
  .in-group-item {
    .margin-rem(@spacing-fourfold 0);
    background: #fff;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
    overflow: hidden;
    a {
      display: block;
    }
  }
  .in-people {
    display: flex;
    justify-content: center;
    li {
      .font-size-rem(18px);
      &:first-child:after {
        .inline-block;
        content: '/';
        width: 1em;
      }
    }
  }
  .in-timer {
    .margin-rem(@spacing-fourfold 0);
    display: flex;
    justify-content: center;
    li {
      .padding-rem(0 20px);
      border-right: 1px solid #ccc;
      text-align: center;
      &:last-child {
        border-right: none;
      }
      span {
        .font-size-rem(36px);
        display: block;
        &.in-time-subtitle {
          .font-size-rem(18px);
          color: #ff3200;
        }
      }
    }
  }
  a {
    color: #fff;
  }
}
