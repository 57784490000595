.in-pagination-container {
  .padding-rem(0 @spacing-double @spacing-double);
  display: flex;
  justify-content: space-between;
  width: 100%;
  &.in-position-absolute {
    bottom: 0;
    left: 0;
    position: absolute;
  }
  .in-pagination-btn {
    .centered-line(2.4rem);
    .padding-rem(0 @spacing-double);
    background: #fff;
    border-radius: 2rem;
    border: 1px solid @green;
    color: @green;
    &.in-disabled {
      border-color: @light-gray;
      color: @light-gray;
    }
  }
}
.paginate {
  display: flex;
  justify-content: flex-end;
  .active {
    border-color: #ff6b00;
    color: #000;
  }
  .disabled {
    color: #ccc;
  }
  .next {
    a {
      padding: 9px 12px;
    }
    i {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
  .prev {
    a {
      padding: 9px 12px;
    }
    i {
      display: inline-block;
      transform: rotate(0deg);
    }
  }
  li {
    margin: 0 4px;
    a {
      border-radius: 2px;
      border: 1px solid #ccc;
      display: inline-block;
      font-size: 14px;
      padding: 10px 14px;
    }
  }
}
.paginate-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  .total {
    font-size: 14px;
    margin-right: 10px;
  }
}
