.in-order-container {
  .in-history-item {
    .margin-rem(@spacing-fourfold);
    align-items: center;
    display: flex;
    justify-content: space-between;
    .in-item-info {
      .margin-rem(0 @spacing-fourfold);
      flex: 1;
      p {
        .font-size-rem(24px);
        .margin-rem(@spacing-double 0);
        line-height: 1.25em;
        .in-item-attr {
          .font-size-rem(20px);
          color: @gray;
        }
      }
    }
    .in-item-pic {
      .size(6rem);
      background-color: @border-color;
    }
  }
  li {
    .margin-rem(@spacing-double 0);
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    > p {
      .padding-rem(@spacing-fourfold @spacing-fourfold);
      color: @light-gray;
      text-transform: uppercase;
      &:first-child {
        border-bottom: 1px solid @border-color;
      }
      &:last-child {
        align-items: center;
        border-top: 1px solid @border-color;
        display: flex;
        justify-content: space-between;
      }
      a {
        .centered-line(2rem);
        .inline-block;
        .padding-rem(0 16px);
        border-color: #000;
        border-radius: 1rem;
        // border: 1px solid @green;
        border: 1px solid #000;
        color: #000;
      }
    }
  }
}
.in-order-content.in-ram-content {
  .in-rma-details-container,.in-slider-header-container {
    top: 4rem;
  }
  .in-slider-header-container.app-slider-container {
    top: 4rem !important;
  }
  .in-view--action {
    justify-content: flex-end;
  }
  .sku {
    color: #999;
    font-size: 12px!important;
  }
}
.in-order-detail-container {
  .margin-bottom-rem(@spacing-fourfold);
  background: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .15);
  overflow: hidden;
  .in-order-info {
    > div {
      .font-size-rem(18px);
      .padding-rem(@spacing-fourfold);
      align-items: center;
      border-bottom: 1px solid @border-color;
      display: flex;
      line-height: 1.25em;
    }
    h6 {
      .font-size-rem(24px);
      line-height: 1.25em;
    }
    i {
      .font-size-rem(60px);
      .margin-right-rem(@spacing-fourfold);
      color: @green;
    }
    p {
      .margin-bottom-rem(@spacing-base);
    }
  }
  .in-order-totals {
    justify-content: space-between;
    a {
      .font-size-rem(24px);
      .padding-rem(@spacing-tripple @spacing-fourfold);
      border-radius: 2rem;
      border: 1px solid @green;
      color: @green;
      text-transform: uppercase;
    }
    span {
      .margin-right-rem(@spacing-base);
    }
  }
  .in-title &:first-of-type {
    border-top: 1px solid @border-color;
  }
  h4 {
    .centered-line(1em);
    .font-size-rem(24px);
    .margin-rem(@spacing-fourfold 0);
    text-align: center;
    text-transform: uppercase;
  }
  li .in-item-qty {
    .font-size-rem(24px);
  }
}
.in-order-search-form {
  .margin-bottom-rem(@spacing-fourfold);
  .padding-rem(@spacing-fourfold);
  background: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .15);
}
.in-order-tab {
  left: 0;
  position: fixed;
  top: 4.5rem;
  width: 100%;
  z-index: 6;
  .in-menu-wrapper .in-menu-icon-wrapper {
    .centered-line(2.7rem);
  }
  .in-preorder-tab {
    align-items: center;
    display: flex;
    height: 100%;
    li {
      flex: 1;
      padding: 0;
    }
  }
  ul {
    li {
      .font-size-rem(24px);
      .padding-rem(0 30px);
      border-right: 1px solid @dark-border;
      line-height: 1.5rem;
      margin-right: 0;
      text-align: center;
      text-transform: uppercase;
      &.active {
        color: @green;
      }
      &:last-child {
        border-right: none;
      }
    }
    li.active {
      color: #000;
    }
  }
}
