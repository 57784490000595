.in-modal-box {
  .in-btn-group {
    .margin-rem(@spacing-fourfold 0);
    justify-content: space-around;
  }
  .in-modal p {
    .font-size-rem(24px);
    color: #000;
  }
  .v--modal {
    background: #fff;
    border-radius: 10px;
  }
  .v--modal-overlay {
    background: rgba(0, 0, 0, .4);
  }
}
.in-modal-box.xp-ui .v--modal {
  border-radius: 0.4rem;
  .in-modal {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 13rem;
    padding: 0.9rem 0.9rem 1.4rem;
    .close_icon {
      align-self: flex-end;
      &:before {
        .IN-iconfont("\e6f7");          transform: rotate(45deg);          color: #999999;          display: block;
      }
    }
    .in-btn-group {
      margin: 1rem 0 0;
      a {
        align-items: center;
        font-size: 1.4rem;
        height: 2.7rem;
        justify-content: center;
        width: 17rem;
      }
    }
    > p {
      clear: both;
      font-size: 1.3rem;
    }
    .addthis_inline_share_toolbox {
      margin-top: 10px;
      a {
        padding: 0;
        .at-icon-wrapper {
          line-height: 28px !important;
          height: 28px !important;
          width: 28px !important;
          svg {
            height: 28px !important;
            width: 28px !important;
          }
        }
      }
    }
  }
}
.in-modal-herf-text {
  color: #666!important;
  color: #666666;
  font-size: 1.2rem!important;
  line-height: 1.4!important;
  word-break: break-all;
}
.share-notes {
  color: #ff3200!important;
  font-size: 0.2rem!important;
  line-height: 1.4!important;
  word-break: break-all;
}
.in-modal-href {
  margin: 0;
  padding: 0 20px;
  word-break: break-all;
}
.in-modal-share-btn {
  align-items: center;
  flex-direction: column;
  a {
    font-size: 14px;
    justify-content: center;
    margin-top: 10px;
    padding: 1rem 0.8rem;
    width: 80%;
  }
}
.in-modal.center {
  justify-content: center !important;
  .in-btn-group {
    margin: 0 !important;
  }
}

.share-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0,0,0,.4);
  .v-modal {
    position: relative;
    overflow: hidden;
    top: 38%;
    left: 12%;
    width: 76%;
    height: auto;
    border-radius: .4rem;
    background: #fff;
    box-shadow: 0 20px 60px -2px rgb(27 33 58 / 40%);
    padding: 0;
    .in-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 13rem;
      padding: .9rem .9rem 1.4rem;
      .in-btn-group {
        margin: 0!important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        font-size: 1.2rem;
        a {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          height: 2.7rem;
          justify-content: center;
          width: 17rem;
          margin-top: 10px;
          padding: 1rem .8rem;
          background: #000;
          border-radius: 2rem;
          color: #fff;
        }
      }
      .addthis_inline_share_toolbox {
        margin-top: 10px;
        a {
          padding: 0;
          .at-icon-wrapper {
            line-height: 2.4rem !important;
            height: 2.4rem !important;
            width: 2.4rem !important;
            svg {
              height: 2.4rem !important;
              width: 2.4rem !important;
            }
          }
        }
      }
    }
  }
}
