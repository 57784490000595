.icon-cart:before,.icon-null:before,.icon-order-success:before,.icon-raty_null:before,.show-time {
  color: #000;
}
.icon-copy {
  background: url("../images/copy.svg") no-repeat center;  background-size: cover;  width: 30px;  height: 30px;  cursor: pointer;
  content: '';
  display: inline-block;
}
.icon-group {
  align-items: center;
  display: flex;
}
.close-icon &:before {
  .IN-iconfont("\e6f7");    transform: rotate(45deg);    color: #999999;    display: block;
}
