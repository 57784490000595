.in-table {
  .in-action {
    color:  @btn-base-color;
    cursor: pointer;
  }
  .talbe-heade {
    background: #ccc;
    color: #fff;
    span {
      font-size: 16px;
    }
  }
  li {
    display: flex;
    flex-direction: row;
    padding: 10px;
    & span {
      flex: 1.3;
      font-size: 14px;
      text-align: center;
      + span {
        flex: 1;
      }
    }
    + li {
      border-top: 1px solid #ccc;
    }
  }
}
.in-table.report-table ul {
  overflow-x: auto;
}
