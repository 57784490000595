.in-slider-container {
  bottom: 5.2rem;
  left: 0;
  position: absolute;
  right: 0;
  top: 4.5rem;
  overflow: auto;
  z-index: 1;
  &.in-slider-no-banner {
    top: 10.5rem;
  }
  &.in-slider-password-container {
    bottom: 0;
    top: 0;
  }
  &.in-slider-sign-container {
    bottom: 0;
    top: 2.5rem;
  }
  .swiper-slide {
    height: auto;
  }
}
.in-slider-footer-container {
  bottom: 3.6rem;
  overflow-y: auto;
}
.in-slider-no-banner .in-flash-date {
  display: none;
}
