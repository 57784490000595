.open-app {
  width: 100%;
  height: 5.25rem;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 999;
  .close {
    width: 14%;
    text-align: center;
    > img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .xpark-logo {
    width: 14%;
    > img {
      width: 4.17rem;
    }
  }
  .content {
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-left: 0.8rem;
    > img {
      width: 8.3rem;
      height: 1.25rem;
    }
    img:last-child {
      margin-top: .5rem;
      height: .75rem;
    }
  }
  .open-btn {
    width: 21%;
    > div {
      border: 2px solid #ff3200;
      border-radius: .5rem;
      padding: .75rem 1.75rem;
      color: #ff3200;
      font-weight: 600;
    }
  }
}
