.in-input-row {
  .centered-line(4rem);
  .margin-rem(@spacing-fourfold 0);
  background: @border-color;
  border-radius: 2em;
  border: 1px solid @input-border-color;
  display: flex;
  &.is-danger {
    border-color: #f00;
  }
  div[class^=in-choose] {
    .padding-rem(0 @spacing-fourfold);
    display: flex;
    flex: 1;
    justify-content: space-between;
    &.in-address-select {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
    }
    span {
      .margin-right-rem(@spacing-fourfold);
      flex: 1;
    }
  }
  i {
    color: @dark-border;
  }
  input {
    .padding-rem(0 @spacing-fourfold);
    background-color: transparent;
    border-radius: 2em;
    color: @font-color;
    flex: 1;
    &:-webkit-autofill {
      box-shadow: inset 0 0 0 66rem @border-color;
    }
  }
  label {
    .padding-rem(0 @spacing-fourfold);
  }
  textarea {
    .padding-rem(6rem @spacing-fourfold);
    background-color: transparent;
    border-radius: 2em;
    color: @font-color;
    flex: 1;
    &:-webkit-autofill {
      box-shadow: inset 0 0 0 120rem @border-color;
    }
  }
}
.in-input-row.select {
  overflow: hidden;
}
