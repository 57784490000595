.in-rma {
  .padding-rem(@spacing-fourfold)!important;
  display: flex;
  justify-content: flex-end;
  a {
    .font-size-rem(24px);
    .padding-rem(@spacing-tripple @spacing-fourfold);
    border-radius: 2rem;
    border: 1px solid @green;
    color: @green;
    display: inline-block;
    text-transform: uppercase;
  }
  span {
    .margin-right-rem(@spacing-base);
  }
}
.in-rma-details-container {
  bottom: 5.2rem;
  left: 0;
  position: absolute;
  right: 0;
  top: 4.5rem;
  &.in-slider-no-banner {
    top: 10.5rem;
  }
  &.in-slider-password-container {
    bottom: 0;
    top: 0;
  }
  &.in-slider-sign-container {
    bottom: 0;
    top: 2.5rem;
  }
  .swiper-slide {
    height: auto;
  }
}
.in-rma-product .checkbox__item {
  border-bottom: 1px solid #e3e3e3;
  line-height: 2.9rem !important;
  margin-top: -1rem !important;
  padding: 15px 0;
  text-align: right;
}
