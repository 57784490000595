.in-wishlist-container {
  .padding-rem(0 10px);
  li {
    .margin-rem(10px 0);
    background: #fff;
    box-shadow: 0 1px 1rem rgba(0, 0, 0, .1);
    display: flex;
    justify-content: space-between;
    padding: 0;
    .in-edit {
      border-left: 1px solid @border-color;
      display: flex;
      flex-direction: column;
      a {
        display: flex;
      }
      div {
        border-bottom: 1px solid @border-color;
        display: flex;
        flex: 1;
        &:last-child {
          border-bottom: none;
        }
      }
      i {
        .font-size-rem(48px);
        .margin-rem(@spacing-fourfold);
        color: @light-gray;
      }
    }
    .in-flag-wrapper {
      .padding-rem(8px);
      left: 0;
      position: absolute;
      top: 0;
      & {
        .in-new-icon {
          background: @green;
        }
        .in-sale-icon {
          background: #ff3200;
        }
        span {
          .centered-line(1.5em);
          .font-size-rem(12px);
          .inline-block;
          .padding-rem(0 10px);
          color: #fff;
        }
      }
    }
    .in-info {
      .padding-rem(@spacing-fourfold);
      display: flex;
      flex: 1;
      position: relative;
      h2 {
        .font-size-rem(24px);
      }
      img {
        background: @border-color;
      }
      p {
        line-height: 1.5em;
        &.in-item-attr {
          .font-size-rem(14px);
          color: @dark-border;
        }
        del {
          .font-size-rem(16px);
          color: @light-gray;
        }
      }
    }
  }
}
