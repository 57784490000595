.account-login {
  .in-login-welcome {
    padding-bottom: 30px !important;
    .login-welcome-panel {
        border-bottom: 1px solid #ccc;
        h3 {
          text-align: left;
          margin-bottom: 30px;
          margin-top: 30px;
          font-weight: bold;
          font-size: 1.5rem;
        }
        padding: 10px 10px 30px;
    
    }
    .login-welcome-others {
        margin-top: 30px;
        font-size: 0.8rem;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        span.go {
            display: inline-block;
            border: 1px solid #000;
            border-bottom-color: rgb(0, 0, 0);
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-left-color: rgb(0, 0, 0);
            border-left-style: solid;
            border-left-width: 1px;
            width: 6px;
            height: 6px;
            position: relative;
            top: 0;
            rotate: 45deg;
            border-bottom: none;
            border-left: none;
            left: 10px;
            top: -1px;
        }
    }
  }
  .in-account-login-container {
    background: white;
    border-radius: 0.3rem;
    box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
    margin: -3.4rem 0.8rem 0;
    padding: 0 0.8rem;
    .in-btn-register {
      font-size: 1.8rem;
    }
    .in-form-action {
      margin: 2.3rem 0 1.8rem;
    }
    .in-form-group {
      margin: 0;
      .in-to-login {
        display: flex;
        justify-content: space-between;
        margin-top: 1.2rem;
        a {
          font-size: 0.9rem;
        }
      }
    }
    .in-input-row {
      border-radius: 0;
      border-width: 0 0 1px;
      margin: 0;
      input {
        border-radius: 0;
        font-size: 1.2rem;
      }
    }
  }
  .in-account-nav h2 {
    font-size: 1.8rem;
  }
  .in-account-password-container {
    background: none;
    padding: 0;
    .err-msg {
      background: #ffd8d5;
      line-height: 1.2;
      padding: 0.5rem;
      &.ret_200 {
        background: #e5efe5;
        color: green;
      }
      a {
        color: @c-orange;
      }
    }
    .in-btn-register {
      box-sizing: border-box;
      display: block;
      font-size: 1.8em;
      height: 3.4rem;
      line-height: 3.4rem;
      margin: 5.5rem auto;
      width: 25rem;
    }
    .in-form-container {
      background: white;
      border-radius: 0.3rem;
      box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
      margin: 4.9rem 0.8rem 0;
      padding: 0.8rem;
    }
    .in-input-row {
      border-color: #f3f3f3;
      border-radius: 1.5rem;
      height: 3rem;
      &.is-danger {
        border-color: red;
      }
      input {
        background: #f3f3f3;
        border-radius: 1.5rem;
        font-size: 1.3rem;
        line-height: 3rem;
      }
    }
    .in-to-login {
      text-align: left;
      a {
        color: #000;
        font-style: normal;
        margin: 0.4rem 0 0;
      }
      p {
        color: #c2c2c2;
        font-size: 1.2rem;
        &:first-child {
          color: #999999;
        }
      }
    }
  }
  .in-account-register-container {
    background: none;
    padding: 0;
    .in-btn-register {
      box-sizing: border-box;
      display: block;
      font-size: 1.8em;
      height: 3.4rem;
      line-height: 3.4rem;
      margin: 5.5rem auto;
      width: 25rem;
    }
    .in-form-container {
      background: white;
      border-radius: 0.3rem;
      box-shadow: 0rem 0.05rem 0.7rem 0rem rgba(0, 0, 0, 0.06);
      margin: 4.9rem 0.8rem 0;
      padding: 0 0.8rem 0.8rem;

      .verification-code-content {
        display: flex;
        gap: 10px;
        justify-content: space-between;

        .in-input-row {
          width: 69%;
        }

        .in-form-group {
          width: 29%;

          .in-btn-register {
            margin: 0;
            width: 100%;
            font-size: 1.2rem;
            line-height: 3rem;
            height: 3rem;
            text-transform: none;
          }
        }
      }

      .send-tip {
        font-size: 12px;
        padding: 0 12px;
        color: #666;
        line-height: 1.2;
      }
    }
    .in-input-row {
      border-color: #f3f3f3;
      border-radius: 1.5rem;
      height: 3rem;
      &.is-danger {
        border-color: red;
      }
      input {
        background: #f3f3f3;
        border-radius: 1.5rem;
        font-size: 1.3rem;
        line-height: 3rem;
      }
    }
  }
  .in-sign-banner {
    align-items: center;
    background: #000;
    display: flex;
    height: 17.2rem;
    justify-content: center;
    img {
      height: auto;
      width: 15rem;
    }
  }
  .in-sign-nav .icon-home &:before {
    .IN-iconfont("\e6cb");
  }
}
