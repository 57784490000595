.netGoodsStepTwoPage {
  .in-choose-state {
    .icon-arrow-down {
      font-size: 1.4rem;
    }
    span {
      color: #333333;
      font-size: 1.3rem;
    }
  }
  .in-input-row {
    background: #f3f3f3;
    line-height: 3rem;
    position: relative;
    .in-address-select {
      position: absolute;
      top: 100%;
      .swiper-slide {
        li {
          height: 1.4rem;
          line-height: 1.4rem;
          &.active {
            background: none;
            &:after {
              .IN-iconfont("\e601");              font-size: 1.3rem;              float: right;              margin-right: 0.8rem;
            }
          }
          span {
            font-size: 1rem;
            height: 1.4rem;
            line-height: 1.4rem;
          }
        }
        ul {
          padding: 0.2rem 0;
        }
      }
    }
  }
  .in-to-login {
    display: none;
  }
}
