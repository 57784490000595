.in-detail-container {
  .in-item-container {
    .margin-bottom-rem(@spacing-double);
    background: #fff;
    padding: 0;
  }
  .in-method-container,.in-payments-container,.in-shipinfo-container {
    align-items: center;
    display: flex;
    line-height: 1.5em;
  }
  .in-order-totals {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 1.5em;
    a {
      .font-size-rem(24px);
      .padding-rem(@spacing-tripple @spacing-fourfold);
      border-color: #000;
      border-radius: 2rem;
      border: 1px solid @green;
      color: #000;
      text-transform: uppercase;
    }
    span {
      .margin-right-rem(@spacing-base);
    }
  }
  > div {
    .padding-rem(0 @spacing-fourfold @spacing-fourfold);
    background: #fff;
    border-bottom: 1px solid @border-color;
    overflow: hidden;
    h2 {
      .centered-line(1em);
      .font-size-rem(32px);
      .margin-rem(@spacing-fourfold 0);
      text-align: center;
      text-transform: uppercase;
    }
  }
  dt,h6 {
    .font-size-rem(24px);
    line-height: 1.25em;
  }
  i {
    .font-size-rem(60px);
    .margin-right-rem(@spacing-fourfold);
    color: @green;
  }
}
.in-details-container {
  margin: 0.4rem 0;
  .in-input-row {
    border-radius: 1.5rem;
    font-size: 1.4rem;
    height: 3rem;
    line-height: 3rem;
    .in-select__wrapper {
      border: none;
    }
    select {
      background: #eee;
      border: none;
      font-size: 22px !important;
    }
    .return select {
      background: transparent;
      margin-top: -11px;
      font-size: 16px !important;
    }
  }
  .in-rma-base,.in-rma-product-container {
    padding: .8rem;
    .in-rma-product {
      border-bottom: 1px solid #eee;
      padding: 1rem 0;
    }
    .in-select__wrapper {
      display: inline-block;
      padding: 4px 10px;
      width: 100px;
    }
    li {
      .font-size-rem(24px);
      line-height: 1.4;
      margin: 8px 0;
    }
  }
  .in-val {
    color: #999;
    display: block;
    margin: 10px 0;
    input[type="number"] {
      background: #f9f9f9;
      width: 100%;
    }
  }
  .subTitle {
    border-bottom: 1px solid #eee;
    color: #999;
    padding: 2rem .8rem .8rem;
    text-transform: capitalize;
  }
  [type="checkbox"] {
    margin-left: 10px;
    vertical-align: middle;
  }
}
