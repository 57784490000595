.in-third-party-login {
  .margin-rem(40px 0);
  text-align: center;
  a {
    .inline-block;
    .margin-rem(0 12px);
    .size(2.8rem);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &.facebook {
      background-image: url('../images/facebook.png');
    }
    &.twitter {
      background-image: url('../images/twitter.png');
    }
  }
}
