.in-tag-container {
  display: flex;
  margin: 30px auto;
  & {
    .in-tag {
      background: #fff;
      border: 1px solid #e5e5e5;
      color: @base-font-color;
      cursor: pointer;
      line-height: 40px;
      text-align: center;
      width: 200px;
      & span {
        .font-size-rem(14);
        display: block;
      }
      &:first-child {
        border-right: none;
      }
      &:last-child {
        border-left: none;
      }
    }
    div.active {
      background: @btn-base-color;
      color: #fff;
    }
    div[class$='in-tag']:hover span {
      color: @btn-base-color;
    }
  }
}
