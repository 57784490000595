.checkbox {
  cursor: pointer;
  line-height: 1;
  margin: 0 !important;
  .icon-check .xp-icon-check {
    display: inline-block;
    line-height: 1;
    &::before {
      .IN-iconfont("\e6d3");        font-size: 30px;
    }
  }
  .in-checkbox-input {
    align-items: center;
    background-color: #f3f3f3;
    background: #e7e7e7;
    border-radius: 50%;
    color: #f3f3f3;
    display: inline-flex;
    height: auto;
    justify-content: center;
    padding: 8px;
    width: auto;
    i {
      font-size: 22px;
    }
  }
  input {
    display: none;
  }
  input:checked {
    & + .in-checkbox-input {
      background-color: #ff6b00;
      background: #ff6b00;
    }
    + .in-checkbox-input {
      background: #000;
    }
  }
}
