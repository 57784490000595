.in-flash-date {
  background: @font-color;
  &.in-flash-date-hidden {
    visibility: hidden;
  }
  &.in-flash-menu {
    position: relative;
    visibility: visible;
    z-index: 3;
  }
  .swiper-slide {
    width: auto;
  }
  li {
    align-items: center;
    background: #4d4d4d;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 3rem;
    justify-content: center;
    line-height: 1.2rem;
    margin-right: 1px;
    width: 25vw;
    &.active {
      background: @green;
    }
  }
  span &.in-flash-status {
    .font-size-rem(16px);
    text-transform: uppercase;
  }
  ul {
    display: flex;
    width: 125vw;
    &.in-origin {
      transform: translateX(-10%);
    }
  }
}
.in-flash-sale-container .in-slider-header-container .in-result {
  top: 47vw;
}
.in-flash-sale-wrapper li {
  .margin-rem(@spacing-double 0);
  .padding-rem(@spacing-fourfold);
  background: #fff;
  .in-item-info {
    flex: 1;
    .in-operation {
      .margin-top-rem(@spacing-fourfold);
    }
    h4 {
      .font-size-rem(28px);
      .margin-rem(@spacing-double 0);
      color: #000;
      font-weight: normal;
      line-height: 1.6em;
    }
    p {
      .margin-rem(@spacing-double 0);
      color: @font-color;
      line-height: 1.2em;
      &.in-item-attr {
        .font-size-rem(18px);
        color: @light-gray;
      }
      del {
        .font-size-rem(16px);
        color: #aaa;
      }
    }
  }
  .in-item-pic {
    .margin-right-rem(@spacing-fourfold);
    .size(9rem);
    overflow: hidden;
  }
  > a {
    align-items: center;
    display: flex;
  }
}
