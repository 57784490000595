.deposit-available {
  background: red;
}
.deposit-btn {
  background: #ff3200;
  bottom: 1rem;
  margin-top: 1rem;
  padding: 0.6rem 0;
  position: absolute;
  right: 0.5rem;
  text-align: center;
  width: 124px;
  span {
    color: #fff;
    font-size: 1.2rem;
  }
}
.deposit-content {
  display: flex;
  justify-content: space-between;
  .deposit-img {
    padding: 1rem 2rem 0.5rem 0;
    width: 12rem;
  }
  div {
    display: inline-block;
  }
}
.deposit-count-down {
  font-weight: bold;
  display: block;
  position: absolute;
  right: 1rem;
  top: -25px;
  background: red;
  border-radius: 10px;
  right: 0;
  padding: 5px;
  span {
    font-size: 1.4rem;
  }
  sup {
    font-size: 40%;
    top: -1rem;
  }
}
.deposit-countdown {
  color: #f3f3f3;
  display: inline-block;
  font-size: 1.4rem;
  margin-top: 1rem;
  sup {
    font-size: 0.4rem;
  }
}
.deposit-discount {
  color: #000;
  font-size: 1.3rem;
  font-weight: 500;
}
.deposit-icon {
  color: #fff;
  display: inline-block;
  font-size: 1.8rem;
  transform: rotate(180deg);
}
.deposit-item {
  border-bottom: 1px solid #dad9d9;
  color: #666;
  padding: 1rem 1.5rem;
}
.deposit-price,.in-required {
  color: red;
}
.deposit-slider {
  top: 4.5rem;
}
.deposit-text {
  background: #f3f3f3;
  margin-top: 0.5rem;
  padding: 1.3rem 1rem;
  position: relative;
  width: 58%;
  p {
    line-height: 1.5rem;
  }
}
