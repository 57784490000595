.border-bottom-1px,.border-left-1px,.border-right-1px,.border-top-1px {
  position: relative;
}
.border-bottom-1px:after {
  -webkit-transform-origin: 0 bottom;
  border-bottom: 1px solid #ebebeb;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  transform-origin: 0 bottom;
  width: 100%;
}
.border-bottom-1px:before,.border-left-1px:after,.border-right-1px:before,.border-top-1px:after {
  -webkit-transform-origin: 0 0;
  content: "";
  display: block;
  position: absolute;
  transform-origin: 0 0;
}
.border-left-1px:before {
  -webkit-transform-origin: left 0;
  border-left: 1px solid #f5f5f5;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: left 0;
}
.border-right-1px:after {
  -webkit-transform-origin: right 0;
  border-right: 1px solid #f5f5f5;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: right 0;
}
.border-top-1px:before {
  -webkit-transform-origin: 0 top;
  border-top: 1px solid #ebebeb;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: 0 top;
  width: 100%;
}
