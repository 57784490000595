.result-list {
  margin: 20px 0;
  word-wrap:break-word;
  &__item {
    display: flex;
    font-size: 18px;
    line-height: 2.2;
    padding: 8px 0;
    text-align: left;
    .label {
      color: #888888;
      width: 30%;
    }
    .value {
      width: 70%;
    }
  }
}
.result-tips {
  color: #000;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 40px;
  word-wrap: break-word;
}
