.in-menu-dropdown {
  .box-shadow(0 3px 5px rgba(0, 0, 0, 0.24));
  .padding-rem(0 @spacing-base);
  background: #fff;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  a {
    .font-size-rem(20px);
    .padding-rem(@spacing-base);
    width: 33.33%;
    span {
      .inline-block;
      border-radius: 4px;
      border: 1px solid @border-color;
      text-align: center;
      width: 100%;
    }
  }
  dd {
    .padding-bottom-rem(@spacing-base);
    display: flex;
    display: none;
    flex-wrap: wrap;
    line-height: 2.25rem;
    > a {
      .inline-block();
    }
  }
  dt {
    .padding-rem(0 @spacing-tripple);
    width: 100%;
  }
}
.in-menu-wrapper {
  position: relative;
  & ul {
    white-space: nowrap;
    & li {
      .inline-block;
      position:relative;
      &:last-child {
        margin-right: 90px;
      }
    }
  }
  .in-menu > span {
    .font-size-rem(24px);
    .padding-rem(0 @spacing-fourfold);
    align-items:center;
    color: @font-color;
    display: flex;
    flex-direction: row;
    &.active + .in-submenu {
      display: block;
    }
    &.active:after {
      .font-size-rem(40px);
      content: "\e620";
    }
    &.xmall-icon:after {
      .font-size-rem(48px);
      content: "\e68a";
      margin:0 5px;
    }
  }
  .in-menu-icon {
    background: #fff;
    box-shadow: -10px 0 12px rgb(255, 255, 255);
  }
  .in-menu-icon-wrapper {
    .centered-line(2.95rem);
    overflow: hidden;
    padding-left: 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
  }
  .in-submenu {
    .padding-rem(20px 30px);
    background:rgba(0,0,0,0.8);
    display: none;
    left: 0;
    position:absolute;
    top: 36px;
    width: 180px;
    a {
      color: #fff;
    }
    h3 a {
      .font-size-rem(28px);
    }
    ul li {
      display: block;
    }
  }
  .in-swiper {
    overflow: visible;
    width: 100%;
  }
  .swiper-slide {
    width: auto;
  }
  a {
    .font-size-rem(24px);
    color: @font-color;
    display: flex;
    &.active {
      border-bottom: 1px solid @green;
      color: @green;
      height: 2.95rem;
    }
  }
  i {
    .font-size-rem(48px);
    .inline-block;
    .padding-rem(0 @spacing-fourfold);
    transition: all .3s ease-in-out;
    &.active {
      transform: rotateZ(180deg);
    }
  }
}
.in-menus-container {
  > div {
    background: #fff;
  }
  h2 {
    .font-size-rem(28px);
    display: flex;
    justify-content: space-between;
    line-height: 3em;
    text-transform: uppercase;
    i {
      .font-size-rem(20px);
      color: @dark-border;
      transform: rotateZ(180deg);
    }
  }
  i {
    .font-size-rem(50px);
  }
  li {
    align-items: center;
    color: #717171;
    display: flex;
    flex-direction: column;
    height: 25vw;
    justify-content: center;
    width: 25%;
    p {
      font-family: RobotoRegular;
    }
  }
  p {
    line-height: 1.2;
    margin-top: 10px;
    text-align: center;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
}
.in-menus-order {
  .margin-bottom-rem(@spacing-double);
  .padding-rem(0 @spacing-fourfold);
  h2 {
    border-bottom: 1px solid @border-color;
  }
}
.in-menus-services {
  .margin-rem(@spacing-double 0);
  box-shadow: 0 5px 5px rgba(0, 0, 0, .05);
  h2 {
    .padding-rem(0 @spacing-fourfold);
  }
  li {
    border-width: 0 1px 1px 0;
    border: 1px solid @border-color;
  }
  ul {
    border-top: 1px solid @border-color;
  }
}
