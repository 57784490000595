.in-select-coupon-container {
  .in-slider-container {
    top: 4.5rem;
  }
  .v--modal {
    border-radius: 0;
  }
}
.in-select-spec-container {
  & .in-title {
    .centered-line(3rem);
    background: @green;
    text-align: center;
    h3 {
      .font-size-rem(32px);
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .in-container {
    .padding-rem(0 20px);
    max-height: 30rem;
    overflow-y: auto;
  }
  .v--modal {
    background-color: #fff;
    border-radius: 0;
    max-height: 33.2rem;
  }
  .v--modal-overlay {
    bottom: 0;
    &.in-absolute {
      position: absolute;
    }
  }
}
.in-select__wrapper {
  border-radius: 100px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  margin: 5px 0 !important;
  padding: 10px 20px;
  width: 100% !important;
  select {
    display: block !important;
    font-size: 16px !important;
    padding: 10px 0 !important;
  }
}
