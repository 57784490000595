.in-bottom-nav {
  a {
    .display(block);
    .padding-bottom-rem(16);
    .padding-top-rem(14);
    color: #999999;
    height: 5.2rem;
    &.active {
      background: @main-color;
      color: #fff;
    }
  }
  a.active {
    background: #000;
  }
  i {
    .font-size-rem(48px);
    vertical-align: middle;
    &.xp-icon-tab-cart {
      position: relative;
      span {
        .centered-line(1rem);
        .font-size-rem(18px);
        background: @font-color;
        border-radius: 50%;
        color: #fff;
        position: absolute;
        right: -.3rem;
        text-align: center;
        top: 0;
        width: 1rem;
      }
    }
  }
  p {
    .margin-top-rem(@spacing-base);
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    li {
      -webkit-flex: 1;
      flex: 1;
      text-align: center;
      .bbsLogo {
        height: 48px;
        width: 60px;
      }
      a {
        position: relative;
        img {
          width: 30px;
          height: 30px;
        }
        div{
          display: flex;
          position: absolute;
          right: 3px;
          top: 3px;
          background: #ff3200;
          color: #fff;
          padding: 3px 5px;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;
        }
        .cart-move {
          width: 50px;
          height: 50px;
          margin-top: -4px;
        }
        .active-text {
          color: #ff3200;
        }
      }
    }
  }
}
