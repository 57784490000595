.in-ads-base-title {
  .inline-block();
  .margin-rem(@spacing-fourfold 0);
  h2 {
    .centered-line(1.33em);
    .font-size-rem(32px);
    font-weight: 400;
    letter-spacing: .1rem;
    text-transform: uppercase;
  }
}
.in-ads-base {
  .margin-rem(24px 0 0);
  overflow: hidden;
  text-align: center;
}
.in-ads-base-content {
  a {
    display: block;
  }
  img {
    .size(100%, 42vw);
  }
}
.in-ads-product {
  overflow: hidden;
  text-align: center;
}
.in-ads-product-content {
  padding: 0.8rem;
  text-align: center;
  > ul {
    padding: 0;
    > li {
      background: white;
      margin-bottom: 0.4rem;
      padding: 0;
      width: calc(~"(100% - 0.4rem) / 2");
      &:nth-child(odd) {
        margin-right: 0.4rem;
      }
      .final-price {
        color: @c-orange;
        font-size: 1.7rem;
        font-weight: 500;
      }
      .in-ads-img img {
        object-fit: scale-down;
      }
      .in-flag-wrapper {
        justify-content: center;
        min-height: 0;
        position: static;
        span {
          font-size: 0.9rem;
          line-height: 1.3rem;
          margin: 0 0.15rem;
          padding: 0 0.4rem;
          &.in-new-icon {
            background: #5db383;
          }
          &.in-sale-icon {
            // background: #ffad70;
            background: #000;
          }
        }
        span.in-new-icon {
          background: #ff3200;
        }
      }
      .old-price {
        color: #999999;
        font-size: 1rem;
        text-decoration: line-through;
      }
      .product-name {
        align-items: center;
        display: flex;
        height: 3rem;
        justify-content: center;
        margin: 0;
        white-space: normal;
        span {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          color: #333333;
          display: -webkit-box;
          font-size: 1.3rem;
          line-height: 1.1;
          overflow: hidden;
          text-align: center;
        }
      }
      .member-price {
        text-align: center;
        margin-bottom: 10px;
        img {
          width: 60%;
        }
      }
      p {
        height: auto;
      }
    }
  }
  li {
    .padding-rem(0);
    border-radius: 4px;
    line-height: 1.6em;
    margin-bottom: 8px;
    width: calc(~"(100% - 8px) / 2");
    &:nth-child(2n+1) {
      margin-right: 8px;
    }
    &:nth-last-child(1),&:nth-last-child(2) {
      margin-bottom: 0;
    }
    .in-ads-img {
      .margin-bottom-rem(@spacing-fourfold);
      align-items: center;
      display: flex;
      height: 0;
      justify-content: center;
      // padding: 50% 0;
      width: 14rem;
      height: 14rem;
      margin: 0 auto;
      img {
        width: auto;
        max-height: 100%;
      }
    }
    .in-flag-wrapper {
      .padding-rem(@spacing-double);
      display: flex;
      justify-content: center;
      min-height: 2.4rem;
      & {
        .in-new-icon {
          background: @main-green;
        }
        .in-sale-icon {
          background: #ff3200;
        }
        span {
          .font-size-rem(18px);
          .margin-left-rem(@spacing-double);
          .padding-rem(0 @spacing-double);
          color: #fff;
        }
      }
    }
    .xp-product-price-area {
      margin-top: 6px;
    }
    > div {
      background: #fff;
      height: 100%;
      padding: 0.8rem 0;
      position: relative;
      > a > p {
        padding: 0 0.8rem;
      }
    }
    a {
      color: @font-color;
    }
    del {
      .font-size-rem(18px);
      color: @light-gray;
    }
    img[lazy=loading] {
      .size(3rem, 3rem);
    }
    p {
      .margin-rem(0);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  ul {
    .padding-rem(0);
    display: flex;
    flex-wrap: wrap;
  }
}
.in-ads-subtitle {
  .margin-bottom-rem(20px);
}
.in-ads-subtitle-content {
  .inline-block();
  .padding-rem(0 35px);
  position: relative;
  & > span {
    .centered-line(1em);
    .margin-rem(10px 0);
    color: @gray;
  }
  &.active {
    &:after {
      background: @main-color;
    }
    i,span {
      color: @main-color;
    }
  }
  &:after {
    .margin-top-rem(6px);
    content: '';
    display: block;
    height: 1px;
    margin: 0 auto;
    width: 100%;
  }
  &:before {
    background: #ccc;
    content: '';
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }
  &:last-child &:before {
    display: none;
  }
  i {
    .font-size-rem(60px);
    color: @gray;
  }
}
.in-ads-timer .in-btn-group a {
  .font-size-rem(16px);
  i {
    .margin-right-rem(10px);
  }
  span {
    vertical-align: 2px;
  }
}
.in-ads-title {
  .margin-rem(32px 0);
  position: relative;
  a {
    position: absolute;
    right: 10px;
    top: 0;
  }
  h2 {
    .font-size-rem(28px);
    font-weight: 400;
    letter-spacing: .1rem;
    line-height: 1;
    text-align: left;
    text-transform: uppercase;
  }
  i {
    .font-size-rem(40px);
    // color: @main-color;
    color: #000;
  }
}
