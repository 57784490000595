.pp-info {
  background: #fff;
  padding: 0 12px;
  .value {
    display: block;
    font-size: 18px;
    word-break: break-all;
  }
  label {
    color: #999999;
    font-size: 18px;
    word-break: break-all;
  }
  li {
    line-height: 2;
    padding: 12px 10px;
    + li {
      border-top: 1px solid #979797;
    }
  }
}
.pp-inventory {
  margin-top: 24px;
  &__body {
    height: 0;
    overflow: hidden;
    transition: all .25s ease-in-out;
  }
  &__content {
    border-top: 1px solid #979797;
    margin: 0 12px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        // font-size: 20px;
        line-height: 1.2;
        padding: 12px;
        width: 50%;
      }
    }
  }
  &__footer {
    padding: 0 12px 1px;
    text-align: right;
    a.in-btn {
      font-size: 16px;
      padding: 5px 10px;
    }
  }
  &__header {
    align-items: center;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    line-height: 1.2;
    padding: 0 6px;
    & h6 {
      color: #333333;
      // font-size: 24px;
      line-height: 1.2;
    }
    .icon-arrow-down {
      font-size: 30px;
    }
  }
  &__item {
    background: #fff;
    border-radius:4px;
    margin: 12px;
  }
}
.pp-order {
  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    li {
      font-size: 12px;
      margin: 12px 0;
      padding: 0 12px;
      text-align: center;
      width: 100% / 3;
      + li {
        border-left: 1px solid #e3e3e3;
      }
    }
  }
  &__content {
    border-top: 1px solid #979797;
    font-size: 14px;
    line-height:1.2;
    margin: 0 -12px;
    label {
      color:rgba(51,51,51,0.5);
      display: inline-block;
    }
    li {
      margin-bottom: 8px;
      span {
        margin-left: 10px;
      }
    }
    ul {
      margin: 24px 24px 12px 24px;
    }
  }
  .icon-arrow-down {
    margin-left: 10px;
  }
  .total {
    font-weight: 500;
  }
}
.pp-order-ship {
  .button-group .in-btn {
    padding: 6px 12px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .card {
    padding: 20px;
    .card-title {
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  .content {
    height: 0;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
  }
  .heading {
    align-items: center;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
  }
  .icon-arrow-down {
    color: #333;
    font-size: 30px;
  }
  li {
    display: block;
    line-height: 1.4;
    &.active .icon-arrow-down {
      display: inline-block;
      transform: rotate(180deg);
      transition: all 0.25s ease;
    }
  }
}
