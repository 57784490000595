.in-search {
  .centered-line(2.8rem);
  .margin-left-rem(@spacing-fourfold);
  .margin-right-rem(@spacing-fourfold);
  background-color: #F3F3F3;
  border-radius: 100px;
  border: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  padding: 0 @spacing-fourfold;
  i {
    .font-size-rem(36px);
    .margin-rem(0 @spacing-base);
    color: #CCCCCC;
    text-align: center;
  }
  input {
    .font-size-rem(24px);
    background-color: #F3F3F3;
    flex: 1;
  }
  input:-moz-placeholder,input:-ms-input-placeholder,input::-moz-placeholder,input::-webkit-input-placeholder {
    color: #aaa;
    font-size: 1.2rem;
  }
}
.in-search-container {
  .in-hot-words-area {
    padding: 20px;
    .in-hot-words {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
      a {
        color: #333333;
      }
      li {
        background-color: #eaeaea;
        border-radius: 2rem;
        color: #333333;
        font-size: 1rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
        padding: 0.4rem 0.8rem;
      }
    }
    .in-hot-words-title {
      align-items: center;
      color: #666666;
      display: flex;
      font-size: 0.9rem;
      line-height: 1;
      i {
        color: @main-color;
        font-size: 1.2rem;
        margin-right: 10px;
      }
    }
  }
  .in-navbar-form {
    align-items: center;
    background-color: #F3F3F3;
    border-radius: 2rem;
    display: flex;
    flex-grow: 1;
    font-size: 1.4rem;
    height: 2.8rem;
    margin-right: 2rem;
    padding: 0 15px;
    input:-moz-placeholder,input:-ms-input-placeholder,input::-moz-placeholder,input::-webkit-input-placeholder {
      color: #aaa;
      font-size: 1.2rem;
    }
  }
  .in-navbar-search-box {
    background-color: #f3f3f3;
    border: 0;
    margin-left: 5px;
  }
  .in-search-panel {
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  .in-search-pre-wrapper {
    a {
      color: #333333;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 10px;
      text-align: center;
      .search-image-area {
        margin: 0 auto;
      }
      .search-max-price {
        .font-size-rem(20px);
        color: #999999;
      }
      .search-price {
        .font-size-rem(34px);
        color: @main-color;
        margin-bottom: 2px;
        margin-top: 8px;
      }
      h6 {
        .font-size-rem(26px);
        margin-top: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 100%;
      }
    }
    li {
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: 8px;
      width: calc(~"(100% - 8px) / 2");
      &:nth-of-type(2n+1) {
        margin-right: 8px;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 16px;
    }
  }
  .in-search-wrapper {
    background-color: #F9F9F9;
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
    .search-empty-text {
      .font-size-rem(28px);
      align-items: center;
      color: #AAAAAA;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .xp-navbar-container {
    flex-shrink: 0;
    .xp-icon-search-text {
      color: #cccccc;
      font-size: 1.8rem;
    }
    i {
      font-size: 2rem;
    }
  }
  input:-moz-placeholder,input:-ms-input-placeholder,input::-moz-placeholder,input::-webkit-input-placeholder {
    font-size: 1.4rem;
  }
}
