.card {
  padding: 20px 30px;
  .card-title {
    font-size: 15px;
    margin-bottom: 20px;
  }
  ul {
    color: #666;
  }
}
.card-bag-entry {
  color: #000;
  cursor: pointer;
}
.card__block {
  & + & {
    border-top: 1px solid #e8e8e8;
    padding-top: 20px;
  }
  &__title {
    padding: 20px 10px;
  }
}
.card__notes {
  color: #B8977F;
  font-size: 14px;
  line-height: 18px;
  margin: 0 auto;
  margin: 30px 0;
  max-width: 430px;
}
.card_heading {
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
}
