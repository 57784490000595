.in-cms-index {
  .in-ads-container {
    .in-ads-base-content {
      margin: 1.2rem 0.8rem;
      img {
        height: auto;
      }
    }
    .in-ads-title {
      .icon-more &:before {
        .IN-iconfont("\e6d5");          color: @c-orange;
      }
      h2 {
        font-size: 1.4rem;
        margin: 1.6rem 0 0.8rem;
        padding: 0 0.8rem;
        text-align: left;
      }
    }
  }
  .in-loading {
    height: 100vh;
    top: auto;
  }
  .in-slider-container {
    top: 13.65rem;
  }
  .in-timer-content {
    align-items: unset;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    min-height: 13rem;
    z-index: 0;
    &.in-timer-center {
      padding: 1.8rem 20%;
    }
    &.in-timer-left {
      padding: 1.8rem 0.8rem 1.8rem 42%;
    }
    &.in-timer-right {
      padding: 1.8rem 40% 1.8rem 0.8rem;
    }
    .in-btn-group .icon-cart {
      display: none;
    }
    .in-timer-desc {
      font-size: 0.9rem;
    }
    .in-timer-price {
      margin: 0.2rem 0 1rem;
    }
    .in-timer-title {
      font-size: 1rem;
      line-height: 1.2em;
      margin: 0 0 0.5rem;
    }
    > img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      z-index: -1;
    }
    ul {
      margin-bottom: 1rem;
      li {
        border: none;
        position: relative;
        &:after {
          content: ":";
          display: block;
          font-family: "RobotoMedium";
          font-size: 1.9rem;
          position: absolute;
          right: -0.2rem;
          top: 0;
        }
        &:last-child::after {
          display: none;
        }
        .in-Montreal {
          font-family: "RobotoMedium";
          font-size: 1.9rem;
        }
      }
    }
  }
  .v--modal-overlay {
    bottom: 0;
    top: auto;
  }
  .xp-header-silder-menu .swiper-slide .xp-menu-item {
    white-space: pre-line;
    word-break: break-word;
  }
}
.in-cms-review {
  .margin-rem(@spacing-double);
  background: #fff;
  overflow: hidden;
  position: relative;
  text-align: center;
  .in-review-pics {
    flex-wrap: nowrap;
    .in-review-img {
      width: 30vw;
      height: 30vw;
      margin: 1.5vw;
      background: #f5f5f5;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 0;
      border-radius: 0;
    }
  }
  .in-review-summary {
    .margin-rem(30px 0);
    small {
      color: @light-gray;
    }
  }
  .swiper-pagination-bullet-active {
    background: #000;
  }
  a {
    color: @font-color;
  }
  h2 {
    .margin-rem(20px 0);
    font-weight: normal;
  }
  i &.active {
    color: @yellow;
  }
  img {
    // .size(30vw);
    max-height: 30vw;
    max-width: 30vw;
    width: auto;
    height: auto;
    // margin: 1.5vw;
    overflow: hidden;
  }
  p {
    line-height: 1.5em;
    &.gray {
      color: @light-gray;
      max-height: 3em;
      overflow: hidden;
    }
  }
  section {
    .margin-bottom-rem(60px);
  }
}
