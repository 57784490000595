.in-catalog-category {
  & .in-product-list {
    .margin-bottom-rem(50);
    .margin-top-rem(50);
  }
  .in-category-nav .in-search {
    &:before {
      .IN-iconfont("\e6cf");        font-size: 1.8rem;        color: #cccccc;
    }
    input {
      height: auto;
      padding-left: 0.2rem;
    }
  }
  .in-sort-by > i {
    &.active {
      // color: @c-orange;
      color: #000;
    }
    &:before {
      .IN-iconfont("\e6d5");
    }
  }
  .in-sort-by-container {
    .in-container {
      padding: 0 1.2rem;
    }
    .in-sort-list li {
      align-items: center;
      border-bottom: 1px solid @c-border;
      display: flex;
      font-size: 1.2rem;
      height: 3.45rem;
      justify-content: space-between;
      padding: 0;
      width: auto;
      &.active {
        &:after {
          .IN-iconfont("\e6d3");            font-size: 1.5rem;            color: #999;            padding: 0 0.4rem;
        }
        span {
          border-bottom: none;
        }
      }
      &:last-child {
        border: none;
      }
    }
  }
  .menu_toggle {
    transition: 300ms;
    &.active {
      color: @c-orange;
    }
    &:before {
      .IN-iconfont("\e6c6");
    }
  }
  .menu_toggle:before {
    color: #000;
  }
  .nav-category-tab {
    position: relative;
    z-index: 6;
    .page_content {
      background: rgba(0, 0, 0, 0.5);
      height: calc(~"100vh - 116px");
      .nav-root {
        background: white;
        min-height: 100%;
        padding: 0 1.2rem;
        width: 20rem;
        li.active > a {
          // color: @c-orange !important;
          color: #000 !important;
          font-weight: bold;
        }
        li.has_children {
          &.open           > a {
            color: #333;
            &:after {
              transform: rotate(180deg);
            }
          }
          > ul {
            display: block;
          }
          > a &:after {
            .IN-iconfont("\e707");              font-size: 1.5rem;
            transition: 300ms;
          }
        }
        li.level0 > a {
          align-items: center;
          border-bottom: 1px solid #e3e3e3;
          color: #333333;
          display: flex;
          font-size: 1.4rem;
          justify-content: space-between;
          line-height: 3.8rem;
        }
        ul.level0 {
          display: none;
          li.level1 > a {
            color: #999;
            font-size: 1.3rem;
            line-height: 2.8rem;
            padding: 0 0.6rem;
          }
        }
      }
    }
  }

  // xpark shop
  .xpark-shop-info {
    background-size: cover;
    display: flex;
    padding: 14px;

    .logo {
      height: 40px;
      width: 40px;
      margin: 10px 10px 0 0;

      img {
        width: 100%;
      }
    }
    .shop-info {
      .title {
        color: #fff;
        padding: 10px 0;
        font-size: 15px;
        text-align: left;
      }
      .description {
        font-size: 11px;
        color:#999999;
        margin-bottom: 10px;
      }
      .contact {
        display: flex;
        font-size: 11px;
        color: #999999;
        .tel {
          margin-right: 10px;
        }
        img {
          width: 10px;
          vertical-align: inherit;
        }
      }
    }
  }
}
