.in-address-content .in-shipping-address {
  .in-edit {
    border-left: 1px solid @border-color;
    display: flex;
    flex-direction: column;
    div {
      align-items: center;
      border-bottom: 1px solid @border-color;
      display: flex;
      flex: 1;
      justify-content: center;
      &:last-child {
        border-bottom: none;
      }
    }
    i {
      .font-size-rem(48px);
      .margin-rem(@spacing-fourfold);
      color: @light-gray;
    }
  }
  .in-info {
    .padding-rem(@spacing-fourfold);
    flex: 1;
    p {
      .margin-bottom-rem(@spacing-double);
      word-break: break-all;
      &:first-of-type {
        .font-size-rem(32px);
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  li {
    .margin-top-rem(16px);
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    display: flex;
    justify-content: space-between;
    padding: 0;
    &.active:after {
      display: none;
    }
  }
  p:first-child {
    .font-size-rem(24px);
  }
}
.in-address-edit-container {
  .padding-rem(0 20px);
  .in-address-default {
    text-align: center;
    i {
      .font-size-rem(30px);
      background: #ff3200;
      border-radius: 50%;
      color: #fff;
    }
    p {
      .font-size-rem(20px);
      line-height: 2em;
      text-transform: uppercase;
    }
  }
}
.in-address-select {
  .padding-left-rem(10);
  .padding-right-rem(10);
  display: block;
  left: 0;
  position: static;
  top: 21px;
  width: 100%;
  z-index: 50;
  & {
    .active {
      background: #707070;
    }
    .in-swiper {
      background: #eee;
    }
    .swiper-container {
      border-radius: 5px;
      box-shadow: 0 0 30px #b5b3b3;
    }
    .swiper-slide li {
      cursor: pointer;
      height: 26px;
      &:hover {
        background: #707070;
      }
      span {
        .centered-line(26px);
        .font-size-rem(14);
        .inline-block();
        .padding-left-rem(20);
        .padding-right-rem(20);
      }
    }
  }
  .picker-footer {
    height: 4rem;
    line-height: 4rem;
  }
  .picker.xp-ui {
    .close_picker &:before {
      .IN-iconfont("\e6f7");        transform: rotate(45deg);        color: #999999;        display: block;
    }
    .picker-panel {
      height: 40rem;
      .picker-choose {
        align-items: center;
        display: flex;
        height: auto;
        justify-content: space-between;
        padding: 0 1.3rem;
        &:after,&:before {
          display: none;
        }
        .picker-title {
          font-size: 1.4rem;
          line-height: 5.9rem;
          text-align: left;
        }
      }
      .picker-content {
        display: flex;
        justify-content: space-between;
        .abc {
          font-size: 1rem;
          width: 2.3rem;
          li {
            background: white;
            line-height: 1.5rem;
            &.active {
              // color: @c-orange;
              color: #999;
            }
          }
        }
        .mask-bottom,.mask-top {
          display: none;
        }
        .wheel-wrapper {
          flex: 1 1 100%;
          padding: 0 1.6rem;
          &.has_abc {
            padding-right: 0;
          }
          .wheel {
            height: 30rem;
            overflow-y: scroll;
            .wheel-scroll {
              margin-top: 0.1rem;
              text-align: left;
              .wheel-item {
                align-items: center;
                background: white;
                border-bottom: 0.1rem solid #f2f2f2;
                display: flex;
                font-size: 1.4rem;
                height: 2.9rem;
                justify-content: space-between;
                line-height: 2.9rem;
                transform: none !important;
                &.active {
                  // color: @c-orange;
                  color: #999;
                }
                &.choose {
                  // color: @c-orange;
                  color: #999;
                  &:after {
                    .IN-iconfont("\e601");
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
