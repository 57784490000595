.coupon__title {
  position: relative;
  text-align: center;
  &::before {
    background: linear-gradient(270deg, rgba(255, 119, 0, 0) 0%, #FF7400 54%, rgba(255, 119, 0, 0) 100%);
    content: '';
    height: 28px;
    left: 50%;
    max-width: 500px;
    opacity: 0.44;
    position: absolute;
    top: 3px;
    transform: translateX(-50%);
    width: 100%;
  }
  span {
    color: #FFDCC2;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 28px;
  }
}
