.in-timer-content {
  .margin-rem(24px 0 0);
  align-items: center;
  display: flex;
  height: 40vw;
  position: relative;
  .in-btn-group {
    margin-top: 0;
    a {
      .font-size-rem(14px);
    }
  }
  .in-timer-area {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-right: 2.6rem;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  .in-timer-desc {
    .font-size-rem(12px);
    color: @gray;
    line-height: 1.2em;
  }
  .in-timer-price {
    .font-size-rem(16px);
    .margin-rem(6px 0 24px);
    color: #999999;
    .is-special-price {
      .margin-left-rem(10px);
    }
    p {
      .inline-block;
      &.in-timer-special-price {
        .font-size-rem(24px);
        color: #333;
      }
    }
  }
  .in-timer-title {
    .font-size-rem(20px);
    .margin-rem(24px 0 0);
    line-height: 1em;
  }
  > img {
    height: auto;
    width: 100%;
  }
  ul {
    display: flex;
    .in-time-split {
      .font-size-rem(32px);
      color: #666;
      padding: 0;
    }
    .in-timer-special-price .price {
      .font-size-rem(26px);
    }
    li {
      .inline-block;
      .padding-rem(0 8px);
      text-align: center;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
      }
      span {
        .font-size-rem(38px);
        display: block;
        &.in-Montreal {
          margin-bottom: 3px;
        }
        &.in-time-subtitle {
          .font-size-rem(14px);
          color: #666666;
        }
      }
    }
  }
}
