.in-account-content {
  .padding-bottom-rem(26px);
  .pp-notification {
    padding: 20px 20px 10px;
    float: right;
    .notify-btn {
        text-decoration: underline;
        border: 0;
        padding: 0;
        cursor: pointer;
        margin-left: 6px;
    }
  }
  .notification-form {
    .modal_body label {
        font-size: 14px;
    }
  }
  .stock-low {
      font-size: 10px;
      color: green;
  }
  .stock-low-label .pp-inventory__header, .stock-low-label .pp-inventory__header h6 {
      color: red;
  }
  .stock-low-label {
    .stock-low {
        color: red;
    }
  }
  .inventory-row {
    width: 50%;
    float: left;
  }
  .inventory-row input {
    border: 1px solid #ccc;
    padding: 5px;
  }
  .inventory-row span {
      float: left;
      padding: 5px;
  }


}
.in-account-content.in-order-content .in-header-nav.in-account-nav.app-serach-header {
  display: flex!important;
  justify-content: flex-end;
}
.in-account-login-container,.in-account-password-container {
  .padding-rem(@spacing-fourfold 60px 0);
  background: @border-color;
}
.in-account-nav {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  height: 4.5rem;
  justify-content: space-between;
  left: 0;
  padding: 0.6rem 0.8rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
  a {
    color: #333;
  }
  i {
    font-size: 2.4rem;
  }
}
.in-account-register-container {
  .padding-rem(@spacing-fourfold 60px 0);
  background: @border-color;
  .in-form-control.in-state {
    position: relative;
  }
}
